import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateShipmentMutation } from '../../../api';
import { PATHS } from '../../../constants/paths';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import {
  FORMATE_DATE,
  HANDLE_FILE_SIZE_CHECK,
  PARSIFY,
  calculateUniversalDate,
} from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import { editShipmentSchema } from '../schema/editShipmentSchema';
import _ from 'lodash';
import axios from 'axios';
export const useEditShipment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [toAddress, setToAddress] = useState(null);
  const [declaredValue, setDeclaredValue] = useState('');
  const [customerDetailModalOpen, setCustomerDetailModal] = useState(false);
  const [fieldsFromGoogle, setFieldsFromGoogle] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem('login-user'));

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [addresses, setAddresses] = useState([]);

  const { shipments } = useSelector((state) => state);

  const [update] = useApi(updateShipmentMutation);

  const { handleSubmit, register, errors, trigger, setValue, watch } =
    useHookFormSubmission(editShipmentSchema);

  const findEditSHipment = shipments?.allShipments.find(
    (shipment) => shipment.id === id
  );
  const [cleanDocuments, setCleanDocuments] = useState(
    findEditSHipment?.documents ? JSON.parse(findEditSHipment?.documents) : []
  );
  const handleUploadAttachmentsCancel = (file) => {
    setCleanDocuments(() => {
      return cleanDocuments.filter((attachment) => {
        return attachment.location !== file.location;
      });
    });
  };
  const handleUploadAttachments = async (files) => {
    setIsLoading(true);
    toast.loading('loading');

    const promises = [];
    const uploadedAttachments = [];

    Object.keys(files).forEach((key) => {
      const document = HANDLE_FILE_SIZE_CHECK(20, files[key]);
      if (document) {
        const formData = new FormData();
        formData.append('upload', files[key]);

        const promise = axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => {
            uploadedAttachments.push(response.data);
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });

        promises.push(promise);
      }
    });

    try {
      await Promise.all(promises);
      toast.dismiss();
      setIsLoading(false);

      setCleanDocuments(_.flatten([...cleanDocuments, ...uploadedAttachments]));
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.dismiss();
      setIsLoading(false);
    }
  };
  const rate =
    findEditSHipment.location &&
    findEditSHipment.location.entity &&
    (PARSIFY(findEditSHipment.to).country == 'United States' ||
      PARSIFY(findEditSHipment.to).country == 'USA'
      ? findEditSHipment.location.entity.rate
      : findEditSHipment.location.entity.internationalRate);

  useEffect(() => {
    if (findEditSHipment) {
      if (findEditSHipment.carrierId) {
        setValue('carrier', findEditSHipment.carrierId);
      }
      if (findEditSHipment.pickUpDate) {
        setValue('pickupDate', findEditSHipment.pickUpDate?.split('T')[0]);
      }
      if (findEditSHipment.deliveryDate) {
        setValue('deliveryDate', findEditSHipment.deliveryDate?.split('T')[0]);
      }
      if (findEditSHipment.totalValue) {
        setValue('declaredValue', declaredValue || findEditSHipment.totalValue);
        setDeclaredValue(declaredValue || findEditSHipment.totalValue);
      }
      if (findEditSHipment.shipmentType) {
        setValue('merchandiseType', findEditSHipment.shipmentType.id);
      }
      if (findEditSHipment.from) {
        setValue('fromLocation', findEditSHipment.from?.id);
      }

      let _addresses = findEditSHipment?.location?.address.map((item) => {
        let address = item?.address ? JSON.parse(item?.address) : {};
        let street = address?.street?.length > 1 ? address.street + ', ' : '';
        let unitNo = address?.unitNo?.length > 1 ? address.unitNo + ', ' : '';
        let country = address?.country || '';

        return {
          id: item.id,
          name: (
            item?.nickName +
            ' (' +
            street +
            unitNo +
            country +
            ')'
          ).replace(/\s+/g, ' '),
        };
      });

      setAddresses(_addresses);
    }
  }, [findEditSHipment]);

  const watchedPickup = FORMATE_DATE(watch('pickupDate'));

  const closeCustomerDetailModal = () => setCustomerDetailModal(false);
  const openCustomerDetailModal = () => setCustomerDetailModal(true);

  const getUpdateCustomerDetailData = (updatedCustomerDetail) => {
    setToAddress(updatedCustomerDetail);
  };

  const streetPlaceholder = () => {
    if (toAddress) {
      return toAddress;
    } else {
      return JSON.parse(findEditSHipment.to);
    }
  };

  const handleDeclaredValue = (value) => {
    setDeclaredValue(value);
  };

  function getTimeZone() {
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? '+' : '-') +
      ('00' + Math.floor(o / 60)).slice(-2) +
      ':' +
      ('00' + (o % 60)).slice(-2)
    );
  }

  const onSubmit = async (values) => {
    if (selectedCountry == '' || selectedCountry == null) {
      // setErrCountry('Country is required ');
      toast.error('Your address is not valid. Please select from the dropdown.')
      return;
    }
    toast.dismiss();
    values.pickUpDate = calculateUniversalDate(
      `${values.pickupDate.toISOString()}${getTimeZone()}`
    );

    values.deliveryDate = calculateUniversalDate(
      `${values.deliveryDate.toISOString()}${getTimeZone()}`
    );
    const modifiedToAddress = {
      name: fieldsFromGoogle,
      city: selectedCity,
      state: selectedState,
      zipCode: zipCode,
      unitNo: values.unitNumber,
      reference: values.referenceNumber,
      country: selectedCountry,
    };
    const { data, errors } = await update({
      shipmentId: parseInt(findEditSHipment.id),
      ...values,
      to: values ? JSON.stringify(modifiedToAddress) : findEditSHipment.to,
      // fromLocationId: parseInt(findEditSHipment.locationId),
      fromLocationId: parseInt(values.fromLocation),
    });
    if (data) {
      navigate(PATHS.SHIPMENTS);
      toast.dismiss();
      toast.success('Successfully updated', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to update', { duration: TOAST_TIME });
    }
  };

  return {
    rate,
    data: findEditSHipment,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    closeCustomerDetailModal,
    openCustomerDetailModal,
    getUpdateCustomerDetailData,
    streetPlaceholder,
    customerDetailModalOpen,
    declaredValue,
    handleDeclaredValue,
    navigate,
    trigger,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    isLoading,
    handleUploadAttachments,
    handleUploadAttachmentsCancel,
    documents: cleanDocuments,
    user,
    addresses, fieldsFromGoogle, setFieldsFromGoogle, zipCode, setZipCode
  };
};
