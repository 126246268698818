import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  approveShipmentMutation,
  cancelShipmentMutation,
  getAllShipments,
} from '../../../api';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import {
  FORMATE_AMOUNT,
  FORMATE_DATE,
  PARSIFY,
} from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import useOutsideClick from '../../../hook/useOutsideClick';
import { storeAllShipmentsAction } from '../../../store';

const usePendingShipmentsList = () => {
  const { shipments } = useSelector((state) => state);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shipmentActionClickedData, setShipmentActionClickedData] =
    useState(null);
  const [shipmentActionClickedId, setShipmentActionClickedId] = useState(null);
  const actionMenuRef = useRef(null);

  const [cancelShipmentCall] = useApi(cancelShipmentMutation);
  const [approveShipmentCall] = useApi(approveShipmentMutation);

  const [getAllShipmentsCall, loading] = useApi(
    getAllShipments,
    storeAllShipmentsAction
  );
  // useEffect(() => getAllShipmentsCall(), [])

  function cleanAddressString(addressString) {
    if (!addressString) return '';

    // Split by commas and clean each part
    const parts = addressString
      .split(',')
      .map((part) => part.trim())
      .filter(Boolean);

    // Helper function to check if strings are similar
    const isSimilar = (str1, str2) => {
      if (!str1 || !str2) return false;
      str1 = str1.toLowerCase().trim();
      str2 = str2.toLowerCase().trim();

      // Check if one is contained within the other
      if (str1.includes(str2) || str2.includes(str1)) {
        return true;
      }

      // Check if they're the same
      return str1 === str2;
    };

    // Keep first occurrence of each unique part
    const seen = new Set();
    const cleanedParts = parts.filter((part) => {
      // Check if we've seen a similar part before
      const isDuplicate = Array.from(seen).some((seenPart) =>
        isSimilar(seenPart, part)
      );

      if (!isDuplicate) {
        seen.add(part);
        return true;
      }
      return false;
    });

    return cleanedParts.join(', ');
  }
  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

  const modifiedShipmentsData = shipments?.allShipments
    .filter(
      (shipment) =>
        shipment.deleted === false &&
        shipment.paid === false &&
        shipment.premium === null
    )
    ?.map((shipment) => {
      const parsedFromAddress = PARSIFY(shipment.from.address);
      // console.log(shipment, 'shipment.from.address')
      // console.log(shipment?.from?.nickName, 'shipment?.from?.nickName')
      // Prepare the address string
      // Assuming the address is already created from parsedFromAddress
      let addressString =
        parsedFromAddress?.street +
        ' ' +
        parsedFromAddress?.city +
        ' ' +
        parsedFromAddress?.state +
        ' ' +
        parsedFromAddress?.country +
        ', ' +
        parsedFromAddress?.zipCode;

      // console.log(addressString, 'addressString')
      const cleanAddress = cleanAddressString(addressString);
      // Create the fromAddress array with the unique address
      const fromAddress = [shipment?.from?.nickName, cleanAddress];
      return {
        ...shipment,
        id: shipment.id,
        entity: shipment?.location.entity.name || '---',
        from: fromAddress,
        recipient: (PARSIFY(shipment.to) && PARSIFY(shipment.to).name) || '---',
        carrier: shipment.carrier && shipment.carrier.name,
        pickup: FORMATE_DATE(shipment?.pickUpDate, 'mm/dd/yy'),
        delivery: FORMATE_DATE(shipment?.deliveryDate, 'mm/dd/yy'),
        value: FORMATE_AMOUNT(shipment.totalValue),
        premium: shipment?.premium
          ? FORMATE_AMOUNT(shipment?.premium)
          : 'UNPAID',
        // premium: PREMIUM(
        //   shipment.totalValue,
        //   shipment &&
        //     shipment.location &&
        //     shipment.location.entity &&
        //     (PARSIFY(shipment.to).country == 'United States' ||
        //       PARSIFY(shipment.to).country == 'USA')
        //     ? shipment.location.entity.rate
        //     : shipment.location.entity.internationalRate
        // ),
        confirmation: shipment.confirmationId ? shipment.confirmationId : 'N/A',
        reference:
          (PARSIFY(shipment.to) && PARSIFY(shipment.to).reference) || '---',
      };
    });

  const handleActionClick = (e, data) => {
    e.stopPropagation();
    setShipmentActionClickedId(parseInt(data?.id));
    setShipmentActionClickedData(data);

    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
  };

  const approveShipmentHandler = async (shipmentId) => {
    toast.dismiss();
    toast.loading('Approving shipment...');
    const { data, errors } = await approveShipmentCall({
      shipmentId,
      // data: {
      //   ...findByIDShipment,
      //   approved: true,
      // },
    });
    if (data) {
      getAllShipmentsCall();
      // console.log('YUNNN');
      toast.dismiss();
      toast.success('Shipment Approved successfully', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in approving shipment', { duration: TOAST_TIME });
    }

    return { data, errors };
  };

  const cancelShipmentHandler = async (shipmentId) => {
    toast.dismiss();
    toast.loading('Deleting shipment...');
    const { data, errors } = await cancelShipmentCall(shipmentId);
    if (data) {
      getAllShipmentsCall();
      toast.dismiss();
      toast.success('Shipment deleted successfully', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in canceling shipment', { duration: TOAST_TIME });
    }

    return { data, errors };
  };

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  return {
    data: modifiedShipmentsData,
    handleActionClick,
    toggleActionMenu,
    actionMenuRef,
    showDeleteModal,
    setShowDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    cancelShipmentHandler,
    shipmentActionClickedData,
    shipmentActionClickedId,
    approveShipmentHandler,
    loading,
  };
};

export default usePendingShipmentsList;
