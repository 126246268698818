import moment from 'moment';
import { useEffect, useState } from 'react';
import { getDashboardStatisticsQuery } from '../../api/services/dashboard.service';
import { monthlyFilter } from '../../constants/monthly-filters';
import { generateYearsArray, getDefaultYearDateRange } from '../../helpers/helpers';
import useApi from '../../hook/useApi';

export const useDashboard = () => {
  const yearsFilterArray = generateYearsArray();
  const [overviewTab, setOverviewTab] = useState('shipment');
  
  // Find the selected year from the array (the one with selected: true)
  const defaultYear = yearsFilterArray.find(year => year.selected)?.value || new Date().getFullYear().toString();
  
  const [selectedMonth, setSelectedMonth] = useState(`all`);
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  
  // Get date range for the current year
  const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultYearDateRange();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [getdata, loading, , data] = useApi(getDashboardStatisticsQuery);

  useEffect(() => {
    getdata({
      endDate: endDate,
      startDate: startDate,
    });
  }, [endDate, startDate]);

  const updateDateRange = (month, year) => {
    if (month === 'all' && year === 'all') {
      setStartDate(`${yearsFilterArray[1].value}-01-01`);
      setEndDate(
        `${yearsFilterArray[yearsFilterArray.length - 1].value}-12-31`
      );
    } else if (month === 'all') {
      setStartDate(`${year}-01-01`);
      setEndDate(`${year}-12-31`);
    } else if (year === 'all') {
      setStartDate(`${yearsFilterArray[1].value}-${month}-01`);
      setEndDate(
        `${
          yearsFilterArray[yearsFilterArray.length - 1].value
        }-${month}-${moment(
          `${month}-${yearsFilterArray[yearsFilterArray.length - 1].value}`,
          'MM-YYYY'
        ).daysInMonth()}`
      );
    } else {
      setStartDate(`${year}-${month}-01`);
      setEndDate(
        `${year}-${month}-${moment(
          `${month}-${year}`,
          'MM-YYYY'
        ).daysInMonth()}`
      );
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    updateDateRange(selectedMonth, event.target.value);
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    updateDateRange(event.target.value, selectedYear);
  };

  const handleOverviewTab = (value) => {
    setOverviewTab(value);
  };

  function calculatePremiumPercentage(premiumData) {
    return premiumData?.reduce(
      (acc, item) => {
        const month = new Date(item?.month).getMonth();
        if (month >= 0 && month <= 2) {
          acc.janMar += item?.premiumPercentage;
        } else if (month >= 3 && month <= 5) {
          acc.aprJun += item?.premiumPercentage;
        } else if (month >= 6 && month <= 8) {
          acc.julSep += item?.premiumPercentage;
        } else if (month >= 9 && month <= 11) {
          acc.octDec += item?.premiumPercentage;
        }
        return acc;
      },
      { janMar: 0, aprJun: 0, julSep: 0, octDec: 0 }
    );
  }

  const premiumChartData = calculatePremiumPercentage(
    data?.dashboardStatistics?.monthlyPremiumPercentage
  );

  function getMonthlyCounts(monthlyData) {
    const monthlyCounts = Array(12).fill(0);
    monthlyData?.forEach((entry) => {
      const month = new Date(entry?.month).getMonth();
      monthlyCounts[month] += entry?.count ? entry?.count : entry?.totalPremium || entry?.totalValue || 0;
    });
    return monthlyCounts;
  }

  const overviewChartTabs = {
    payment: getMonthlyCounts(data?.dashboardStatistics?.monthlyBilling),
    manualClaims: getMonthlyCounts(data?.dashboardStatistics?.monthlyManualClaimsCount),
    shipmentClaims: getMonthlyCounts(data?.dashboardStatistics?.monthlyShipmentClaimsCount),
    billing: getMonthlyCounts(data?.dashboardStatistics?.monthlyBilling),
    shipment: getMonthlyCounts(data?.dashboardStatistics?.monthlyShipmentsCount),
    entities: getMonthlyCounts(data?.dashboardStatistics?.monthlyEntitiesCount),
    shipmentValue: getMonthlyCounts(data?.dashboardStatistics?.monthlyShipmentsTotalValue),
  };

  const chartData = overviewChartTabs[overviewTab] || [];

  function formatNumber(value, isCurrency = false) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: isCurrency ? 'currency' : 'decimal',
      currency: 'USD',
      maximumFractionDigits: 2,
    });

    if (value >= 1_000_000_000) {
      return formatter.format(value / 1_000_000_000) + 'B';
    } else if (value >= 1_000_000) {
      return formatter.format(value / 1_000_000) + 'M';
    } else if (value >= 1_000) {
      return formatter.format(value / 1_000) + 'K';
    } else {
      return formatter.format(value);
    }
  }

  const currentYear = !Number.isNaN(Number(selectedYear))
    ? Number(selectedYear)
    : new Date().getFullYear();

  const overviewChartXAxisData = [
    `${currentYear} January`,
    `${currentYear} February`,
    `${currentYear} March`,
    `${currentYear} April`,
    `${currentYear} May`,
    `${currentYear} June`,
    `${currentYear} July`,
    `${currentYear} August`,
    `${currentYear} September`,
    `${currentYear} October`,
    `${currentYear} November`,
    `${currentYear} December`,
  ];

  const getDataSourceForCurrentTab = () => {
    switch (overviewTab) {
      case 'payment':
        return data?.dashboardStatistics?.monthlyBilling;
      case 'manualClaims':
        return data?.dashboardStatistics?.monthlyManualClaimsCount;
      case 'shipmentClaims':
        return data?.dashboardStatistics?.monthlyShipmentClaimsCount;
      case 'billing':
        return data?.dashboardStatistics?.monthlyBilling;
      case 'shipment':
        return data?.dashboardStatistics?.monthlyShipmentsCount;
      case 'entities':
        return data?.dashboardStatistics?.monthlyEntitiesCount;
      case 'shipmentValue':
        return data?.dashboardStatistics?.monthlyShipmentsTotalValue;
      default:
        return data?.dashboardStatistics?.monthlyShipmentsCount;
    }
  };

  const currentDataSource = getDataSourceForCurrentTab();

  function generateDynamicXAxisLabels() {
    // Always use the fixed array of all months for the selected year
    const year = !Number.isNaN(Number(selectedYear)) 
      ? Number(selectedYear) 
      : new Date().getFullYear();
    
    return [
      `${year} January`,
      `${year} February`,
      `${year} March`,
      `${year} April`,
      `${year} May`,
      `${year} June`,
      `${year} July`,
      `${year} August`,
      `${year} September`,
      `${year} October`,
      `${year} November`,
      `${year} December`,
    ];
  }

  const dynamicXAxisLabels = generateDynamicXAxisLabels();

  function getDynamicChartData() {
    // Get the current selected year
    const year = !Number.isNaN(Number(selectedYear)) 
      ? Number(selectedYear) 
      : new Date().getFullYear();
    
    // Create a map for existing data
    const dataMap = new Map();
    
    // If we have data, process it
    if (currentDataSource && currentDataSource.length > 0) {
      currentDataSource.forEach(entry => {
        const entryDate = new Date(entry.month);
        const dateKey = `${entryDate.getFullYear()}-${String(entryDate.getMonth() + 1).padStart(2, '0')}`;
        dataMap.set(dateKey, entry.count || entry.totalPremium || entry.totalValue || 0);
      });
    }
    
    // Create data for all months of the selected year
    const allMonthsData = [];
    for (let month = 0; month < 12; month++) {
      const dateKey = `${year}-${String(month + 1).padStart(2, '0')}`;
      allMonthsData.push(dataMap.get(dateKey) || 0);
    }
    
    return allMonthsData;
  }

  const dynamicChartData = getDynamicChartData();

  const chartColors = ['#2A2522', '#443830', '#5D4839', '#77553C'];

  const overviewChartOptions = {
    title: {
      left: 'center',
      top: '13%',
      textStyle: {
        fontSize: 18,
        fontWeight: 'Normal',
      },
    },
    height: '90%',
    width: '95%',
    tooltip: {
      trigger: 'axis',
      show: true,
      axisPointer: {
        show: false,
        type: 'cross',
        label: {
          backgroundColor: '#DAEFFF',
          color: '#000000',
          fontWeight: '550',
        },
      },
    },
    legend: {
      show: false,
      data: [`${overviewTab}`],
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      top: '7%',
      containLabel: true,
    },
    xAxis: {
      show: true,
      type: 'category',
      data: dynamicXAxisLabels,
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: {
        rotate: 45,
        fontSize: 12,
      },
    },
    yAxis: {
      show: true,
      axisLabel: { show: true },
      splitNumber: 4,
    },
    series: [
      {
        name: overviewTab,
        type: 'bar',
        data: dynamicChartData,
        color: ['#77553D'],
        label: {
          show: true,
          position: 'top',
          formatter: (params) => formatNumber(params.value),
        },
        barWidth: '40%',
      },
    ],
  };

  const shipmentChartOptions = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      borderColor: 'transparent',
      formatter: function (params) {
        const { seriesName, value, color, name: month } = params;
        return `
          <div>
            <div><strong>${month}</strong></div>
            <div>
              <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>
              ${seriesName}: ${value}
            </div>
          </div>`;
      },
    },
    legend: {
      show: false,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        axisLabel: {
          show: false,
        },
        splitNumber: 3,
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Entities',
        type: 'bar',
        stack: 'entity',
        emphasis: {
          focus: 'series',
        },
        barWidth: '40%',
        data: getMonthlyCounts(data?.dashboardStatistics?.monthlyEntitiesCount),
        color: ['#5D4839'],
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
      },
    ],
  };

  const premiumChartOptions = {
    legend: {
      top: 'middle',
      right: 'right',
      icon: 'circle',
    },
    toolbox: {
      show: false,
    },
    series: [
      {
        name: 'Nightingale Chart',
        type: 'pie',
        radius: [30, 100],
        center: ['35%', '50%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 5,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
            formatter: function (params) {
              return Math.round(params.value);
            },
          },
          labelLine: {
            show: true,
          },
        },
        data: [
          {
            value: premiumChartData?.janMar ?? 0,
            name: 'Jan - Mar',
            itemStyle: { color: chartColors[0] },
          },
          {
            value: premiumChartData?.aprJun ?? 0,
            name: 'Apr - Jun',
            itemStyle: { color: chartColors[1] },
          },
          {
            value: premiumChartData?.julSep ?? 0,
            name: 'Jul - Sep',
            itemStyle: { color: chartColors[2] },
          },
          {
            value: premiumChartData?.octDec ?? 0,
            name: 'Oct - Dec',
            itemStyle: { color: chartColors[3] },
          },
        ],
      },
    ],
  };

  return {
    data: data?.dashboardStatistics,
    overviewTab,
    loading,
    overviewChartOptions,
    shipmentChartOptions,
    premiumChartOptions,
    handleOverviewTab,
    selectedMonth,
    monthlyFilter,
    selectedYear,
    yearsFilterArray,
    handleMonthChange,
    handleYearChange,
  };
};
