import {
  AddPaymentMethodAlertComponent,
  ClientsPanelHeader,
  CustomHeading,
} from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import useEntities from '../Entities/useEntities';
import { AllEntities, NewEntities } from '../../../constants/columns';
import NoEntitiesComponent from '../noEntityComponent';
import { DeleteModal } from '../../modals';
import EntitiesActionMenu from '../EntitiesActionMenu';
import ApproveModal from '../../modals/approve-modal';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import EntityChangeRate from '../EntityChangeRate';
import PolicyDocumentModal from '../PolicyDocumentModal';
import CircularILoader from '../../../common/custom/circularLoader';
import EntityManualPaymentModal from '../EntityManualPaymentModal';

const EntitiesTableLayout = () => {
  const {
    entitiesData,
    newEntitiesData,
    filterStatus,
    handleAllEntities,
    handleNewApplication,
    handleActionClick,
    actionMenuRef,
    entitiesActionClickedData,
    toggleActionMenu,
    openSuspendModal,
    closeSuspendModal,
    openDeteleModal,
    closeDeleteModal,
    showDeleteModal,
    showSuspendModal,
    openApprovalModal,
    showApprovalModal,
    closeApprovalModal,
    suspendEntityHandler,
    cancelEntityHandler,
    approveEntityHandler,
    entityActionClickedId,
    openChangeRateModal,
    showChangeRateModal,
    closeChangeRateModal,
    showChangeSalesMemberModal,
    openChangeSalesMemberModal,
    closeChangeSalesMemberModal,
    showPolicyModal,
    openPolicyModal,
    closePolicyModal,
    policyDocumentHandler,
    closeManualPaymentModal,
    openManualPaymentModal,
    isManualPaymentModal,
    manualPaymentHandler,
    loading,
  } = useEntities();

  const showNoEntitiesData = !entitiesData?.length;
  const showNoNewEntitiesData = !newEntitiesData?.length;
  return (
    <>
      {loading ? (
        <div class='flex h-[126px] justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl'>
          <CircularILoader />
        </div>
      ) : (
        <>
          <ClientsPanelHeader />
          <div className='border-[1px] border-[#EAECF0] border-[color:var(--Gray-200,#EAECF0)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)] rounded-xl border-solid   '>
            <AddPaymentMethodAlertComponent />
            <CustomContainer className='px-4 min-h-[75px] flex justify-between items-center w-full'>
              <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600] max-w-[1152px] h-full'>
                Entities
              </CustomHeading>
              <div className='flex w-auto justify-end'>
                <div
                  className={`p-2 mr-2 flex w-[150px] justify-center items-center text-small-para font-[500] text-filter-heading cursor-pointer ${filterStatus === 'new'
                    ? 'bg-brown-background text-foundation-brown rounded-md'
                    : 'text-filter-heading'
                    }`}
                  onClick={handleNewApplication}
                >
                  New Applications
                </div>
                <div
                  className={`p-2 mr-2 flex w-[100px] justify-center items-center text-small-para font-[500] text-filter-heading cursor-pointer ${filterStatus === 'all'
                    ? 'bg-brown-background text-foundation-brown rounded-md'
                    : 'text-filter-heading'
                    }`}
                  onClick={handleAllEntities}
                >
                  All Entities
                </div>
              </div>
            </CustomContainer>
            {filterStatus === 'all' ? (
              showNoEntitiesData ? (
                <NoEntitiesComponent text={'There are no entities yet.'} />
              ) : (
                <CustomTable
                  table='/entities'
                  header={AllEntities}
                  rows={entitiesData}
                  handleActionClick={handleActionClick}
                  actionMenuRef={actionMenuRef}
                  toggleActionMenu={toggleActionMenu}
                  menu={
                    <EntitiesActionMenu
                      data={entitiesActionClickedData}
                      filterStatus={filterStatus}
                      openPolicyModal={openPolicyModal}
                      openSuspendModal={openSuspendModal}
                      openDeteleModal={openDeteleModal}
                      openChangeRateModal={openChangeRateModal}
                      openChangeSalesMemberModal={openChangeSalesMemberModal}
                      openManualPaymentModal={openManualPaymentModal}
                      entityActionClickedId={entityActionClickedId}
                    />
                  }
                />
              )
            ) : showNoNewEntitiesData ? (
              <NoEntitiesComponent text={'There are no new entities yet.'} />
            ) : (
              <CustomTable
                table='/entities'
                header={NewEntities}
                rows={newEntitiesData}
                handleActionClick={handleActionClick}
                actionMenuRef={actionMenuRef}
                toggleActionMenu={toggleActionMenu}
                menu={
                  <EntitiesActionMenu
                    data={entitiesActionClickedData}
                    filterStatus={filterStatus}
                    openDeteleModal={openDeteleModal}
                    openApprovalModal={openApprovalModal}
                  />
                }
              />
            )}
            <div>
              {showSuspendModal ? (
                <ApproveModal
                  modalHeading={
                    entitiesActionClickedData?.isBlocked
                      ? 'Continue Entity'
                      : 'Suspend Entity?'
                  }
                  modalPara={`Are you sure you want to ${entitiesActionClickedData?.isBlocked
                    ? 'continue'
                    : 'suspend'
                    } this Entity?`}
                  approvalHandler={() =>
                    suspendEntityHandler(entityActionClickedId)
                  } // manage handle for both filter
                  closeApproveModal={closeSuspendModal}
                  buttonText={
                    entitiesActionClickedData?.isBlocked
                      ? 'Continue'
                      : 'Suspend'
                  }
                />
              ) : showDeleteModal ? (
                <DeleteModal
                  modalHeading='Delete Entity?'
                  modalPara='Are you sure you want to delete this Entity? This action cannot be undone.'
                  deleteHandler={() =>
                    cancelEntityHandler(entityActionClickedId)
                  } // manage handle for both filter
                  closeDeleteModal={closeDeleteModal}
                />
              ) : showApprovalModal ? (
                <ApproveModal
                  modalHeading='Approve Entity'
                  modalPara='Are you sure you want to approve this Entity? This action cannot be undone.'
                  approvalHandler={() =>
                    approveEntityHandler(entityActionClickedId)
                  } // manage handle for both filter
                  closeApproveModal={closeApprovalModal}
                />
              ) : showChangeRateModal ? (
                <EntityChangeRate
                  entityId={entityActionClickedId?.data.id}
                  closeChangeRateModal={closeChangeRateModal}
                  modalHeading='Change Premium Rate'
                />
              ) : showChangeSalesMemberModal ? (
                <EntityChangeRate
                  entityId={entityActionClickedId?.data.id}
                  closeChangeRateModal={closeChangeSalesMemberModal}
                  modalHeading='Change Sales Person'
                />
              ) : showPolicyModal ? (
                <PolicyDocumentModal
                  entityId={entityActionClickedId?.data.id}
                  closeModal={closePolicyModal}
                  modalHeading='Attach Policy Document'
                />
              ) : isManualPaymentModal ? (
                <EntityManualPaymentModal
                  entity={entityActionClickedId?.data}
                  closeManualPaymentModal={closeManualPaymentModal}
                  manualPaymentHandler={manualPaymentHandler}
                  modalHeading='Manual Payment'
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EntitiesTableLayout;
