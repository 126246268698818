import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { updateReportShipmentFormAction } from '../../../../store';

import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getAllEntitiesQuery, getEntity } from '../../../../api';
import {
  fetchCities,
  fetchCountries,
  fetchStates,
} from '../../../../api/services/address.service';
import { PARSIFY } from '../../../../helpers/helpers';
import useApi from '../../../../hook/useApi';
import { reportShipmentStepOneSchema } from '../../schema/reportShipmentStepOneSchema';

export const useReportShipmentStepOne = (increaseStepHandler, setIsTrial) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fieldsFromGoogle, setFieldsFromGoogle] = useState(null);
  const { reportShipment } = useSelector((state) => state.forms);
  const [getApprovedEntities] = useApi(getAllEntitiesQuery);
  const [getSingleEntity] = useApi(getEntity);

  let { handleSubmit, register, errors, trigger, setValue, watch, reset } =
    useHookFormSubmission(reportShipmentStepOneSchema);

  const [entities, setEntities] = useState([]);
  const [entityMembers, setEntityMembers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showMissingPM, setShowMissingPM] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedZipCode, setSelectedZipCode] = useState(null);
  const [addressStreet, setAddressStreet] = useState(null);

  const [errCountry, setErrCountry] = useState(null);
  const [errState, setErrState] = useState(null);
  const [errCity, setErrCity] = useState(null);

  const countryValue = watch('country');
  const stateValue = watch('state');
  const cityValue = watch('city');
  const entityValue = watch('entity');

  useEffect(() => {
    const getEntity = async () => {
      if (entityValue) {
        const _selected = await getSingleEntity(parseInt(entityValue));
        var _shipCount = 0;

        _selected?.data?.entity?.entityMember.map((item) => {
          _shipCount += item?.Shipment?.length;
        });

        if (_shipCount > 0) {
          setIsTrial(false);
        } else {
          setIsTrial(true);
        }
        setShowMissingPM(
          _selected?.data?.entity?.location[0]?.defaultPaymentMethodID
            ? false
            : true
        );

        setSelectedEntity(_selected?.data?.entity);
        const _addresses = _selected?.data?.entity?.location[0]?.address.map(
          (item) => {
            const parsedAddress = PARSIFY(item?.address);
            const completeAddress =
              parsedAddress?.street + ', ' + parsedAddress?.country;

            return {
              label:
                (item.nickName ? item.nickName : 'N/A') +
                ' (' +
                completeAddress +
                ')',
              name:
                (item.nickName ? item.nickName : 'N/A') +
                ' (' +
                completeAddress +
                ')',
              value: item.id,
              id: item.id,
            };
          }
        );
        const _entityMembers = _selected?.data?.entity?.entityMember.map(
          (item) => ({
            label: item.firstName + ' ' + item?.lastName,
            name: item.firstName + ' ' + item?.lastName,
            value: item.id,
            id: item.id,
          })
        );
        setAddresses(_addresses ? _addresses : []);
        setEntityMembers(_entityMembers ? _entityMembers : []);
      }
    };

    getEntity();
  }, [entityValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _countries = await fetchCountries();

        _countries = _countries.map((item) => ({
          label: item.country,
          value: item.country,
        }));

        setCountries(_countries);
      } catch (error) {
        // console.error('Error fetching countries:', error);
      }
    };

    fetchData();

    setValue(
      'country',
      reportShipment.formData.step1?.country
        ? reportShipment.formData.step1.country
        : ''
    );
    setValue(
      'entity',
      reportShipment.formData.step1?.entity
        ? reportShipment.formData.step1.entity
        : ''
    );
    setValue(
      'from',
      reportShipment.formData.step1?.from
        ? reportShipment.formData.step1.from
        : ''
    );
    setValue(
      'entityMember',
      reportShipment.formData.step1?.entityMember
        ? reportShipment.formData.step1.entityMember
        : ''
    );
    setValue(
      'destination',
      reportShipment.formData.step1?.destination
        ? reportShipment.formData.step1.destination
        : ''
    );
    setValue(
      'reference',
      reportShipment.formData.step1?.reference
        ? reportShipment.formData.step1.reference
        : ''
    );
    setValue(
      'unitNumber',
      reportShipment.formData.step1?.unitNumber
        ? reportShipment.formData.step1.unitNumber
        : ''
    );
    setValue(
      'streetAddress',
      reportShipment.formData.step1?.streetAddress
        ? reportShipment.formData.step1.streetAddress
        : ''
    );
    setFieldsFromGoogle(
      reportShipment.formData.step1?.streetAddress
        ? reportShipment.formData.step1.streetAddress
        : ''
    );
    setValue(
      'zipCode',
      reportShipment.formData.step1?.zipCode
        ? reportShipment.formData.step1.zipCode
        : ''
    );
    const getEntities = async () => {
      let _entities = await getApprovedEntities();
      // console.log('entities', _entities?.data?.allEntity);
      _entities = _entities?.data?.allEntity?.map((item) => ({
        label: item.name,
        name: item.name,
        value: item.id,
        id: item.id,
      }));
      setEntities(_entities);
    };
    getEntities();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _states = await fetchStates(
          selectedCountry ? selectedCountry : countryValue
        );
        _states = _states.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setStates(_states);
        setSelectedState(null);
        setSelectedCity(null);
      } catch (error) {
        // console.error('Error fetching states:', error);
      }
    };

    fetchData();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _cities = await fetchCities(
          selectedCountry ? selectedCountry : countryValue,
          selectedState ? selectedState : stateValue
        );
        _cities = _cities.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setCities(_cities);
      } catch (error) {
        // console.error('Error fetching cities:', error);
      }
    };

    fetchData();
    setSelectedCity();
    setValue(
      'city',
      selectedCity ? selectedCity : reportShipment.formData.step1?.city
    );
  }, [
    useMemo(
      () => [selectedCountry, countryValue, selectedState, stateValue],
      [selectedCountry, countryValue, selectedState, stateValue]
    ),
  ]);

  const onCancel = () => {
    reset();
    navigate(-1);
  };

  const onSubmit = (state) => {
    // if (addressCountry == '' || addressCountry == null) {
    //   // setErrCountry('Country is required ');
    //   toast.error('Your address is not valid. Please select from the dropdown.')
    //   return;
    // }
    // console.log('submit::', state);
    // if (selectedCountry == '' || selectedCountry == null) {
    //   setErrCountry('Country is required ');
    //   return;
    // }
    // if (selectedState == '' || selectedState == null) {
    //   setErrState('State is required ');
    //   return;
    // }
    // if (selectedCity == '' || selectedCity == null) {
    //   setErrCity('City is required ');
    //   return;
    // }
    const values = {
      ...state,

      streetAddress: addressStreet,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      locationId: selectedEntity?.location[0]?.id,
      zipCode: selectedZipCode
      // entity: state?.entityMember,
    };

    // console.log('sectedEntity', selectedEntity);
    console.log('values:', values);

    if (selectedEntity?.location[0]?.defaultPaymentMethodID) {
      toast.dismiss();
      increaseStepHandler();
      dispatch(
        updateReportShipmentFormAction({
          step: 1,
          values,
        })
      );
    } else {
      setShowMissingPM(true);
    }
  };

  const handleClosePmModal = () => setShowMissingPM(false);

  const handleGoogleAutoComplete = (newAddress, address) => {
    console.log(address, 'address')
    setFieldsFromGoogle(newAddress);
    setSelectedCountry(address?.country)
    setAddressStreet(address?.street)
    setSelectedState(address?.state)
    setSelectedCity(address?.city)
    setSelectedZipCode(address?.zipCode)
  };
  console.log(selectedZipCode, 'selectedZipCode')
  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleGoogleAutoComplete,
    trigger,
    setValue,
    setCountries,
    countries,
    setCountries,
    countryValue,
    stateValue,
    fieldsFromGoogle,
    states,
    setStates,
    cities,
    setCities,
    cityValue,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    errCountry,
    errState,
    errCity,
    entities,
    addresses,
    entityMembers,
    showMissingPM,
    handleClosePmModal,
    onCancel,
    selectedZipCode,
    setSelectedZipCode
  };
};
