import { gql } from '@apollo/client';
import client from '../apollo.';

export const reportShipmentMutation = async (reportShipmentData) => {
  reportShipmentData.document = JSON.stringify(
    _.flatten(reportShipmentData.document)
  );
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation($createShipmentInput: ShipmentCreateInput!) {
        createShipment(createShipmentInput: $createShipmentInput) {
          id
          from {
            nickName
          }

          location {
            name
          }

          additionalNote
          confirmationId
          createdAt
          deliveryDate
          approved
          pickUpDate
        }
      }
    `,
    variables: {
      createShipmentInput: {
        additionalNote: reportShipmentData.additionalNote,
        carrier: {
          connect: {
            // id: carrierId,
            id: parseInt(reportShipmentData.carrier),
          },
        },
        // deliveryDocuments,
        deliveryDocuments: '',
        // documents,
        documents: reportShipmentData.document,
        isTrial: reportShipmentData.isTrial,
        deliveryDate: reportShipmentData.deliveryDate,
        location: {
          connect: {
            id: reportShipmentData.locationId,
          },
        },
        entityMember: {
          connect: {
            // id: reportShipmentData.entityId, //uid here
            id: parseInt(reportShipmentData.entityMember), //uid here
          },
        },
        from: {
          connect: {
            // id: from,
            id: reportShipmentData.from,
          },
        },
        shipmentType: {
          connect: {
            // id: parseInt(merchType),
            id: 2, // todo.. hardcoded ?
          },
        },
        // otherCarrier,
        otherCarrier: '',
        pickUpDate: reportShipmentData.pickupDate,
        to: JSON.stringify({
          name: reportShipmentData.destination,
          reference: reportShipmentData.reference,
          street: reportShipmentData.streetAddress,
          unitNo: parseInt(reportShipmentData.unitNumber),
          city: reportShipmentData.city,
          zipCode: reportShipmentData.zipCode,
          state: reportShipmentData.state,
          country: reportShipmentData.country,
        }),
        totalValue: parseInt(reportShipmentData.declaredValue),
        hasExpensiveItem: false,
        subscribers: reportShipmentData.isTrial?.toString(),
      },
    },
  });
  return { data, errors };
};

export const updateShipmentMutation = async (updateDatat) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation UpdateShipment(
        $shipmentWhereUniqueInput: ShipmentWhereUniqueInput!
        $updateShipmentInput: ShipmentUpdateInput!
      ) {
        updateShipment(
          shipmentWhereUniqueInput: $shipmentWhereUniqueInput
          updateShipmentInput: $updateShipmentInput
        ) {
          id
        }
      }
    `,
    variables: {
      shipmentWhereUniqueInput: {
        id: updateDatat.shipmentId,
      },
      updateShipmentInput: {
        from: {
          connect: {
            id: updateDatat.fromLocationId,
          },
        },
        pickUpDate: {
          set: updateDatat.pickUpDate,
        },
        to: {
          set: updateDatat.to,
        },
        totalValue: {
          set: updateDatat.declaredValue,
        },
        deliveryDate: {
          set: updateDatat.deliveryDate,
        },
        deliveryDocuments: {
          set: '',
        },
        otherCarrier: {
          set: '',
        },
        documents: {
          set: updateDatat.documents,
        },
        carrier: {
          connect: {
            id: parseInt(updateDatat.carrier),
          },
        },
        additionalNote: {
          set: updateDatat.additionalNote,
        },
        shipmentType: {
          connect: {
            id: parseInt(updateDatat.merchandiseType),
          },
        },
      },
    },
  });
  return { data, errors };
};

export const deliverShipmentMutation = async ({ shipmentId, documents }) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation deliverShipment(
        $shipmentWhereUniqueInput: ShipmentWhereUniqueInput!
        $updateShipmentInput: ShipmentUpdateInput!
      ) {
        deliverShipment(
          shipmentWhereUniqueInput: $shipmentWhereUniqueInput
          updateShipmentInput: $updateShipmentInput
        ) {
          id
        }
      }
    `,
    variables: {
      shipmentWhereUniqueInput: {
        id: shipmentId,
      },
      updateShipmentInput: {
        receipt: {
          set: documents,
        },
        isDelivered: {
          set: true,
        },
      },
    },
  });
  return { data, errors };
};

export const refundShipmentMutation = async (shipmentId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation RefundShipment($refundShipmentId: Int!) {
        refundShipment(id: $refundShipmentId) {
          id
        }
      }
    `,
    variables: {
      refundShipmentId: shipmentId,
    },
  });
  return { data, errors };
};

export const cancelShipmentMutation = async (shipmentId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation CancelShipment($cancelShipmentId: Int!) {
        cancelShipment(id: $cancelShipmentId) {
          id
        }
      }
    `,
    variables: {
      cancelShipmentId: shipmentId,
    },
  });
  return { data, errors };
};

export const getAllShipmentsFromLocationQuery = async (locationId) => {
  const { data, errors } = await client.query({
    query: gql`
      query Shipments($locationId: Float!) {
        allShipmentFromLocation(locationId: $locationId) {
          additionalNote
          confirmationId
          createdAt
          deliveryDate
          approved
          isTrial
          isManuallyPaid
          subscribers
          id
          location {
            name
            entity {
              id
              rate
              name
              internationalRate
              isBlocked
              userId
            }
          }
          claim {
            id
            createdAt
          }
          deliveryDocuments
          shipmentType {
            id
            name
          }
          documents
          isDelivered
          locationId
          paid
          pickUpDate
          reason
          status
          to
          totalValue
          verifiedById
          carrierId
          entityMember {
            lastName
            id
            firstName
          }
          carrier {
            name
          }
          from {
            nickName
            address
          }
        }
      }
    `,
    variables: {
      locationId: locationId,
    },
  });

  return { data, errors };
};

export const getShipmentQuery = async (id) => {
  const { data, errors } = await client.query({
    query: gql`
      query Shipment($shipmentId: Int!) {
        shipment(id: $shipmentId) {
          additionalNote
          createdAt
          carrierId
          isTrial
          isManuallyPaid
          subscribers
          deliveryDate
          shipmentType {
            id
            name
          }
          documents
          deliveryDocuments
          from {
            nickName
            id
            address
          }
          claim {
            id
            createdAt
          }
          id
          otherCarrier
          pickUpDate
          to
          totalValue
          locationId
        }
      }
    `,
    variables: {
      shipmentId: id,
    },
  });
  return { data, errors };
};

export const approveShipmentMutation = async (approveSHipmentParams) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation approveShipment(
        $shipmentWhereUniqueInput: ShipmentWhereUniqueInput!
        $updateShipmentInput: ShipmentUpdateInput!
      ) {
        approveShipment(
          shipmentWhereUniqueInput: $shipmentWhereUniqueInput
          updateShipmentInput: $updateShipmentInput
        ) {
          id
        }
      }
    `,
    variables: {
      shipmentWhereUniqueInput: {
        id: approveSHipmentParams.shipmentId,
      },
      updateShipmentInput: {
        isDelivered: {
          set: approveSHipmentParams.data.isDelivered,
        },
        approved: {
          set: approveSHipmentParams.data.approved,
        },
        isManuallyPaid: {
          set: approveSHipmentParams.data.isManuallyPaid,
        },
      },
    },
  });
  return { data, errors };
};

export const changeConfirmStatusMutation = async (shipmentId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation changeShipmentStatus(
        $shipmentWhereUniqueInput: ShipmentWhereUniqueInput!
        $updateShipmentInput: ShipmentUpdateInput!
      ) {
        changeShipmentStatus(
          shipmentWhereUniqueInput: $shipmentWhereUniqueInput
          updateShipmentInput: $updateShipmentInput
        ) {
          id
          confirmationId
        }
      }
    `,
    variables: {
      shipmentWhereUniqueInput: {
        id: shipmentId,
      },
      updateShipmentInput: {
        confirmationId: {
          set: 'true',
        },
      },
    },
  });
  return { data, errors };
};

export const changePaidStatusMutation = async (shipmentId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation changeShipmentStatus(
        $shipmentWhereUniqueInput: ShipmentWhereUniqueInput!
        $updateShipmentInput: ShipmentUpdateInput!
      ) {
        changeShipmentStatus(
          shipmentWhereUniqueInput: $shipmentWhereUniqueInput
          updateShipmentInput: $updateShipmentInput
        ) {
          id
          paid
        }
      }
    `,
    variables: {
      shipmentWhereUniqueInput: {
        id: parseInt(shipmentId),
      },
      updateShipmentInput: {
        paid: {
          set: true,
        },
      },
    },
  });
  return { data, errors };
};

export const getAllShipments = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query Shipments {
        shipments {
          additionalNote
          confirmationId
          createdAt
          deliveryDate
          approved
          isTrial
          isManuallyPaid
          subscribers
          id
          deliveryDocuments
          shipmentType {
            id
            name
          }
          location {
            name
            entity {
              id
              rate
              name
              internationalRate
              isBlocked
              userId
            }
            address {
              id
              nickName
              address
            }
          }
          documents
          isDelivered
          locationId
          paid
          pickUpDate
          reason
          status
          paymentIntentId
          to
          concludedOn
          totalValue
          premium
          verifiedById
          carrierId
          entityMember {
            lastName
            id
            firstName
          }
          carrier {
            name
          }
          claim {
            id
            createdAt
          }
          from {
            id
            nickName
            address
          }
        }
      }
    `,
  });
  return { data, errors };
};

export const allShipmentFromEntityQuery = async (entityID) => {
  const { data, errors } = await client.query({
    query: gql`
      query allShipmentFromEntity($entityId: Float!, $entityId2: Int!) {
        allShipmentFromEntity(entityId: $entityId) {
          additionalNote
          confirmationId
          createdAt
          deliveryDate
          approved
          id
          isTrial
          isManuallyPaid
          subscribers
          deliveryDocuments
          shipmentType {
            id
            name
          }
          location {
            name
            entity {
              rate
              name
              internationalRate
              isBlocked
              userId
            }
            address {
              id
              nickName
              address
            }
          }
          claim {
            id
            createdAt
          }
          documents
          isDelivered
          locationId
          paid
          pickUpDate
          reason
          status
          paymentIntentId
          to
          concludedOn
          totalValue
          premium
          verifiedById
          carrierId
          entityMember {
            lastName
            id
            firstName
          }
          carrier {
            name
          }
          from {
            id
            nickName
            address
          }
        }
        entity(id: $entityId2) {
          name
        }
      }
    `,
    variables: {
      entityId: entityID,
      entityId2: parseInt(entityID.toString()),
    },
  });
  return { data, errors };
};
