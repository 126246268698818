import { gql } from '@apollo/client';
import client from '../apollo.';

export const fileClaimMutation = async ({
  receipts,
  invoice,
  entityId,
  attachments,
  locationId,
  internalNote,
  shipmentId,
  bestWayToContact,
  customerEmail,
  customerPhone,
  description,
  claimAmount,
  partToBeClaimed,
  internalComments,
  filedByName,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation fileClaimMutation($createClaimInput: ShipmentClaimCreateInput!) {
        createClaim(createClaimInput: $createClaimInput) {
          id
        }
      }
    `,
    variables: {
      createClaimInput: {
        receipts: receipts,
        invoice: invoice,
        attachments: attachments,
        entity: {
          connect: {
            id: entityId,
          },
        },
        location: {
          connect: {
            id: locationId,
          },
        },
        shipment: {
          connect: {
            id: shipmentId,
          },
        },
        filedByUser: {
          connect: {
            id: 0,
          },
        },
        customerNote: internalNote,
        bestWayToContact: bestWayToContact,
        customerEmail: customerEmail,
        customerPhone: customerPhone,
        description: description,
        claimAmount: claimAmount,
        partToBeClaimed: partToBeClaimed,
        internalComments: internalComments,
        filedByName,
      },
    },
  });
  return { data, errors };
};

//

export const getAllClaimsQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query AllMyClaims {
        allMyClaims {
          id
          claimActivities {
            description
            subject
            id
            createdAt
          }
          claimValue
          createdAt
          customerEmail
          customerPhone
          filedByUser {
            id
            lastName
            firstName
          }
          settled
          status
          claimAmount
          filedByName
          partToBeClaimed
          internalComments
          shipment {
            totalValue
            to
            carrier {
              name
            }
            shipmentType {
              name
            }
            from {
              nickName
            }
          }
        }
      }
    `,
  });

  return { data, errors };
};

export const updateClaimMutation = async ({
  claimId,
  claimAmount,
  status,
  internalNote,
  claimCloseDoc,
  customerEmail,
  customerPhone,
  customerNote,
  description,
  receipts,
  invoice,
  attachments,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $updateClaimInput: ShipmentClaimUpdateInput!
        $updateClaimId: Float!
      ) {
        updateClaim(updateClaimInput: $updateClaimInput, id: $updateClaimId) {
          id
        }
      }
    `,
    variables: {
      updateClaimId: claimId,
      updateClaimInput: {
        status: {
          set: status,
        },
        internalNote: {
          set: internalNote,
        },
        claimCloseDoc: {
          set: claimCloseDoc,
        },
        claimAmount: { set: Number(claimAmount) },
        customerEmail: { set: customerEmail },
        customerPhone: { set: customerPhone },
        customerNote: { set: customerNote },
        description: { set: description },
        receipts: { set: receipts },
        invoice: { set: invoice },
        attachments: { set: attachments },
      },
    },
  });

  return { data, errors };
};

// export const getAllActiveClaimsQuery = async () => {
//   const { data, errors } = await client.query({
//     query: gql`
//       query ActiveClaims {
//         activeClaims {
//           id
//           filedByUser {
//             id
//             firstName
//             lastName
//           }
//           entity {
//             name
//             id
//           }
//           createdAt
//           claimValue
//           status
//           internalNote
//           customerNote
//         }
//       }
//     `,
//   });
//   return { data, errors };
// };

export const getAllActiveClaimsQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query ActiveClaims {
        activeClaims {
          claims {
            id
            filedByUser {
              id
              firstName
              lastName
            }
            entity {
              name
              id
            }
            shipment {
              totalValue
            }
            createdAt
            claimValue
            status
            claimId
            internalNote
            customerNote
            claimAmount
            partToBeClaimed
            internalComments
            filedByName
          }
          manualClaims {
            id
            filedByUser {
              id
              firstName
              lastName
            }
            filedByEntityMember {
              id
              firstName
              lastName
            }
            filedByUserType
            entity {
              name
              id
            }
            filedByName
            createdAt
            claimValue
            adjustedAmount
            status
            claimId
            internalNote
            customerNote
            fileNumber
            referenceNumber
          }
        }
      }
    `,
  });
  return { data, errors };
};

export const getClaimDetailsQuery = async (claimId) => {
  const { data, errors } = await client.query({
    query: gql`
      query claim($claimId: Int!) {
        claim(id: $claimId) {
          attachments
          bestWayToContact
          claimValue
          createdAt
          customerEmail
          customerNote
          customerPhone
          description
          entity {
            id
            name
          }
          filedByUser {
            firstName
            lastName
            id
          }
          id
          internalNote
          invoice
          location {
            id
            name
          }
          receipts
          settled
          status
          claimAmount
          partToBeClaimed
          internalComments
          claimActivities {
            subject
            description
            id
            createdAt
          }
          shipment {
            id
            from {
              address
              nickName
            }
            to
            totalValue
            receipt
            shipmentType {
              name
              id
            }
            confirmationId
            concludedOn
            carrier {
              name
            }
            approvedOn
            additionalNote
            additionalFiles
            approved
            createdAt
            deliveryDate
            deliveryDocuments
            documents
            hasExpensiveItem
            paid
            paymentStatus
            pickUpDate
            premium
            status
          }
        }
      }
    `,
    variables: {
      claimId: claimId,
    },
  });
  return { data, errors };
};

export const createManualClaimMutation = async ({
  receipts,
  invoice,
  entityId,
  attachments,
  // locationId,
  internalNote,
  bestWayToContact,
  customerEmail,
  customerPhone,
  description,
  fileNumber,
  referenceNumber,
  claimValue,
  filedById,
  filedByName,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation createManualClaim($createInput: ManualClaimCreateInput!) {
        createManualClaim(createInput: $createInput) {
          id
        }
      }
    `,
    variables: {
      createInput: {
        receipts: receipts,
        invoice: invoice,
        attachments: attachments,
        entity: {
          connect: {
            id: entityId,
          },
        },
        // location: {
        //   connect: {
        //     id: locationId,
        //   },
        // },
        filedByUser: {
          connect: {
            id: filedById,
          },
        },
        customerNote: internalNote,
        bestWayToContact: bestWayToContact,
        customerEmail: customerEmail,
        customerPhone: customerPhone,
        description: description,
        fileNumber: fileNumber,
        referenceNumber: referenceNumber,
        claimValue: claimValue,
        filedByUserType: 'User',
        filedByName,
        adjustedAmount: 0,
      },
    },
  });
  return { data, errors };
};

export const getManualClaimDetailsQuery = async (claimId) => {
  const { data, errors } = await client.query({
    query: gql`
      query manualClaim($claimId: Int!) {
        manualClaim(id: $claimId) {
          attachments
          bestWayToContact
          claimValue
          adjustedAmount
          createdAt
          filedByName
          customerEmail
          customerNote
          customerPhone
          description
          signedReleaseClaim
          entity {
            id
            name
          }
          filedByUser {
            firstName
            lastName
            id
          }
          claimActivities {
            id
            claimId
            subject
            description
            createdAt
          }
          id
          internalNote
          invoice
          receipts
          settled
          status
          claimId
        }
      }
    `,
    variables: {
      claimId: claimId,
    },
  });
  return { data, errors };
};

export const updateManualClaimMutation = async ({
  claimId,
  claimAmount,
  status,
  internalNote,
  claimCloseDoc,
  customerEmail,
  customerPhone,
  customerNote,
  description,
  receipts,
  invoice,
  attachments,
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $updateManualClaimInput: ManualClaimUpdateInput!
        $updateManualClaimId: Float!
      ) {
        updateManualClaim(
          updateManualClaimInput: $updateManualClaimInput
          id: $updateManualClaimId
        ) {
          id
        }
      }
    `,
    variables: {
      updateManualClaimId: claimId,
      updateManualClaimInput: {
        // status: {
        //   set: status,
        // },
        // internalNote: {
        //   set: internalNote,
        // },
        // claimCloseDoc: {
        //   set: claimCloseDoc,
        // },
        // claimAmount: { set: Number(claimAmount) },

        status: {
          set: status,
        },
        internalNote: {
          set: internalNote,
        },
        claimCloseDoc: {
          set: claimCloseDoc,
        },
        adjustedAmount: { set: Number(claimAmount) },
        customerEmail: { set: customerEmail },
        customerPhone: { set: customerPhone },
        customerNote: { set: customerNote },
        description: { set: description },
        receipts: { set: receipts },
        invoice: { set: invoice },
        attachments: { set: attachments },
      },
    },
  });

  return { data, errors };
};

export const updateTradeShowClaimMutation = async ({
  claimId,
  releasefile,
  status,
  claimValue,
  claimAmount,
  customerEmail,
  customerPhone,
  customerNote,
  description,
  receipts,
  internalNote,
  invoice,
  attachments,
  claimCloseDoc
}) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $tradeShowClaimWhereUniqueInput: TradeShowClaimWhereUniqueInput!
        $updateTradeShowClaimInput: TradeShowClaimUpdateInput!
      ) {
        UpdateTradeShowClaimAdmin(
          tradeShowClaimWhereUniqueInput: $tradeShowClaimWhereUniqueInput
          updateTradeShowClaimInput: $updateTradeShowClaimInput
        ) {
          id
        }
      }
    `,
    variables: {
      tradeShowClaimWhereUniqueInput: { id: claimId },
      updateTradeShowClaimInput: {
        status: {
          set: status,
        },
        internalNote: {
          set: internalNote,
        },
        claimCloseDoc: {
          set: claimCloseDoc,
        },
        adjustedAmount: { set: Number(claimAmount) },
        customerEmail: { set: customerEmail },
        customerPhone: { set: customerPhone },
        customerNote: { set: customerNote },
        description: { set: description },
        receipts: { set: receipts },
        invoice: { set: invoice },
        attachments: { set: attachments },
      },
    },
  });

  return { data, errors };
};

export const createTradeShowClaimMutation = async (createTradeShowClaimInput) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
    mutation createTradeShowClaim($createTradeShowClaimInput:TradeShowClaimCreateInput!){
      createTradeShowClaim(
        createTradeShowClaimInput:$createTradeShowClaimInput
      ){
        invoice
      }
    }
    `,
    variables: {
      createTradeShowClaimInput
    }
  })
  return { data, errors };
}
export const getAllTradeShowClaimQuery = async () => {
  const { data, errors } = await client.mutate({
    mutation: gql`
   query findAllTradeShowClaims{
      AllTradeShowClaims{
        id
        EntityMember{
          firstName
          lastName
          id
        }
        claimActivities{
          description
          subject
        }
        filedByUserType
        createdAt
        claimValue
        invoice
        receipts
        attachments
        adjustedAmount
        status
        claimId
        internalNote
        customerNote
        fileNumber
        filedByName
        referenceNumber
      }
    }
    `,
  })
  return { data, errors };
}

export const getTradeShowClaimDetailsQuery = async (claimId) => {
  const { data, errors } = await client.query({
    query: gql`
      query tradeShowClaim($claimId: Int!) {
        tradeShowClaim(id: $claimId) {
          attachments
          bestWayToContact
          claimValue
          createdAt
          customerEmail
          customerNote
          customerPhone
          description
          claimActivities {
            id
            claimId
            subject
            description
            createdAt
          }
          id
          internalNote
          invoice
          receipts
          settled
          status
          adjustedAmount
          claimCloseDoc
          claimId
          signedReleaseClaim
        }
      }
    `,
    variables: {
      claimId: claimId,
    },
  });
  return { data, errors };
};