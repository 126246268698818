import React, { useEffect } from 'react';
import {
  CustomButton,
  CustomDropdown,
  CustomHeading,
  CustomModal,
  CustomText,
} from '../../../../common/custom';
import CustomInput from '../../../../common/custom/CustomInput';
import { useReportShipmentStepOne } from './useReportShipmentStepOne';
import CustomDropdown2 from '../../../../common/custom/custom-drop-down/index2';
import AutoCompleteComponent from '../../../components/auto-complete';
import { alertCircleIcon, cross } from '../../../../assets';
import { CustomIcon } from '../../../../common/customAssets';
import CustomDropdown3 from '../../../../common/custom/custom-drop-down/index3';

const ReportShipmentStepOne = ({ increaseStepHandler, setIsTrial }) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleGoogleAutoComplete,
    trigger,
    setValue,
    countries,
    setCountries,
    countryValue,
    stateValue,
    fieldsFromGoogle,
    states = [],
    setStates,
    cities = [],
    setCities,
    cityValue,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    errCountry,
    errState,
    errCity,
    entities,
    addresses,
    entityMembers,
    showMissingPM,
    handleClosePmModal, selectedZipCode,
    onCancel,
  } = useReportShipmentStepOne(increaseStepHandler, setIsTrial);


  console.log(selectedZipCode, 'selectedZipCode')
  return (
    <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
      <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
        Report Shipment
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid gap-4'>
          <div
          // className='grid grid-cols-1 md:grid-cols-2 gap-4'
          >
            <div>
              {/* <CustomDropdown
                placeholder='Select Entity'
                options={entities}
                setValue={setValue}
                name='entity'
                label='Entity'
                errors={errors}
              /> */}
              <CustomDropdown3
                placeholder='Select Entity'
                options={entities}
                setValue={(e) => setValue('entity', e)}
                name='entity'
                label='Entity'
                errors={errors}
              />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div>
              {/* <CustomDropdown
                placeholder='Select Entity Member'
                options={entityMembers}
                setValue={setValue}
                name='entityMember'
                label='Entity Member'
                errors={errors}
              /> */}
              <CustomDropdown3
                placeholder='Select Entity Member'
                options={entityMembers}
                setValue={(e) => setValue('entityMember', e)}
                name='entityMember'
                label='Entity Member'
                errors={errors}
              />
            </div>
            <div>
              <CustomDropdown3
                placeholder='Select From Address'
                options={addresses}
                setValue={(e) => setValue('from', e)}
                name='from'
                label='From'
                errors={errors}
              />
              {/* <CustomDropdown
                placeholder='Select From Address'
                options={addresses}
                setValue={setValue}
                name='from'
                label='From'
                errors={errors}
              />
            */}
            </div>
          </div>
          <div>
            <CustomInput
              register={register}
              name='destination'
              placeholder='Destination(e.g. Recipient or storage facility)*'
              required
              type='text'
              errors={errors}
              label='Destination'
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div>
              <CustomInput
                register={register}
                name='reference'
                placeholder='Enter reference#'
                type='text'
                label='Reference'
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomInput
                register={register}
                name='unitNumber'
                placeholder='Enter unit number'
                type='text'
                label='Unit Number'
                trigger={trigger}
                setValue={setValue}
              />
            </div>
          </div>
          {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='md:mt-[6px]'>
              <CustomDropdown2
                label='Country*'
                placeholder={'Select Country'}
                options={countries}
                setOptions={setCountries}
                name='country'
                error={errCountry}
                setValue={setSelectedCountry}
                value={selectedCountry}
              />
            </div>

            <div className='md:mt-[6px]'>
              <CustomDropdown2
                label='State*'
                placeholder={'Select State'}
                options={states}
                setOptions={setStates}
                name='state'
                error={errState}
                setValue={setSelectedState}
                value={selectedState}
              />
            </div>
          </div> */}
          {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='md:mt-[6px]'>
              <CustomDropdown2
                label='City*'
                placeholder={'Select City'}
                options={cities}
                setOptions={setCities}
                name='city'
                error={errCity}
                setValue={setSelectedCity}
                value={selectedCity}
              />
            </div>
            <div>
              <CustomInput
                register={register}
                name='zipCode'
                placeholder='Enter your zipcode'
                required
                type='text'
                label='Zip Code'
                errors={errors}
                trigger={trigger}
                setValue={setValue}
                value={selectedZipCode}
              />
            </div>
          </div> */}
          <div>
            {/* <AutoCompleteComponent
              setValue={setValue}
              trigger={trigger}
              isFormatted={true}
              errors={errors}
              register={register}
              label='Street Address'
              name='streetAddress'
              address={fieldsFromGoogle}
              onChange={handleGoogleAutoComplete}
              required
            /> */}
            <AutoCompleteComponent
              setValue={setValue}
              trigger={trigger}
              isFormatted={true}
              errors={errors}
              register={register}
              label='Street Address'
              name='streetAddress'
              address={fieldsFromGoogle}
              onChange={handleGoogleAutoComplete}
              required
            />
          </div>
          <div className='flex justify-end mt-4 gap-[32px]'>
            <button
              type='button'
              className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
              onClick={onCancel}
            >
              Cancel
            </button>{' '}
            {/* <CustomButton className='brown-bg-btn' text='Next' /> */}
            <button
              type='submit'
              className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
            >
              Next
            </button>
          </div>
        </div>
      </form>
      {showMissingPM && (
        <CustomModal>
          <div className='max-w-[544px] h-[204px] bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center   '>
            <div className='max-w-[476px] text-[#77553d] w-full h-[72px] gap-1 flex justify-between'>
              <div className=''>
                <div className='flex justify-between w-full'>
                  <CustomHeading className='text-[18px] flex gap-2 font-inter leading-[28px] font-[600] mb-1 min-w-[440px]'>
                    <CustomIcon
                      icon={alertCircleIcon}
                      className=' h-[24px] w-[24px]'
                    />
                    Missing Payment Method
                  </CustomHeading>
                  <div
                    onClick={handleClosePmModal}
                    className=' cursor-pointer h-[24px] flex justify-end max-w-[40px]'
                  >
                    <CustomIcon
                      icon={cross}
                      className=' h-[12px] w-[12px] flex justify-center items-center'
                    />
                  </div>
                </div>
                <br />
                <CustomText>
                  Cannot report shipment. Payment method is missing
                </CustomText>
              </div>
            </div>
            <div className=' h-[44px] flex justify-end gap-[12px] w-full max-w-[476px] mt-[36px] '>
              <CustomButton
                onClick={handleClosePmModal}
                type='button'
                text='Close'
                className='w-[87px] h-[44px] bg-[#77553d] rounded-[8px] text-pure-white'
              />
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default ReportShipmentStepOne;
