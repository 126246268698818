import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';

function useAllClaims(isLoading, currentTab) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [claimActionClickedId, setClaimActionClickedId] = useState(null);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);

  useEffect(() => {
    isLoading ? toast.loading('Loading...') : toast.dismiss();
  }, []);

  const closeDeleteModal = () => setShowDeleteModal(false);

  const openViewClaimModal = (claim) => {
    navigate(
      `${
        currentTab == 'claims' ? PATHS.CLAIM_DETAIL : currentTab == 'tradeShow' ?
          PATHS.TRADE_SHOW_CLAIM_DETAIL : PATHS.MANUAL_CLAIM_DETAIL
      }/${claim.id}`
    );
  };

  const cancelClaimHandler = async () => {
    return { data: {} };
  };

  const handleActionClick = (e, data) => {
    e.stopPropagation();
    setClaimActionClickedId({ data });

    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
    data && localStorage.setItem('claim', JSON.stringify(data));
  };

  return {
    showDeleteModal,
    closeDeleteModal,
    toggleActionMenu,
    openViewClaimModal,
    cancelClaimHandler,
    handleActionClick,
    claimActionClickedId,
  };
}

export default useAllClaims;
