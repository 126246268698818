import { plusGray } from '../../../assets';
import { CustomButton, CustomModal } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { PAYMENT_METHODS } from '../../../constants/columns';
import DeleteModal from '../../modals/delete-modal';
import NoEntitiesComponent from '../noEntityComponent';
import PaymentActionMenu from './paymentLocationActionMenu';
import StripeCardDetailsForm from './StripeForm';
import UsePaymentEntity from './usePaymentEntity';
const PaymentLocationTable = () => {
  const {
    handleActionClick,
    actionMenuRef,
    toggleActionMenu,
    paymentActionClickedData,
    id,
    getSetupIntent,
    openStripeForm,
    setupIntent,
    onCloseStripeForm,
    paymentMethods,
    openDeleteModal,
    setOpenDeleteModal,
    handleDeleteCard,
    refreshPaymentMethods,
  } = UsePaymentEntity();
  return (
    <>
      {openDeleteModal && (
        <DeleteModal
          modalHeading='Delete Payment Method?'
          modalPara='Are you sure you want to delete this payment method? This action cannot be undone.'
          deleteHandler={() => handleDeleteCard(id)}
          closeDeleteModal={() => setOpenDeleteModal(false)}
        />
      )}
      {openStripeForm && setupIntent && (
        <CustomModal open={openStripeForm} onClose={onCloseStripeForm}>
          <div className='!pt-4 mt-4 pb-4 min-w-[400px] p-3 shadow-[0px_0px_10px_0px_rgba(0,0,0,0.14)] rounded-xl bg-[#fff]'>
            <StripeCardDetailsForm
              setupIntent={setupIntent}
              onCloseStripeForm={onCloseStripeForm}
              refreshPaymentMethods={refreshPaymentMethods}
            />
          </div>{' '}
        </CustomModal>
      )}
      <CustomContainer className='mt-4'>
        <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
          <div className='flex justify-between'>
            <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
              Payment Information
            </h1>
            <CustomButton
              onClick={getSetupIntent}
              icon={plusGray}
              text='Add New'
              className={`bg-foundation-white px-[16px]  rounded-[8px] border border-[1px]  border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500] mt-4 sm:mt-0  gap-3`}
            />
          </div>
          {paymentMethods?.length > 0 ? (
            <CustomTable
              table='/payment-location'
              header={PAYMENT_METHODS}
              rows={paymentMethods}
              handleActionClick={handleActionClick}
              actionMenuRef={actionMenuRef}
              toggleActionMenu={toggleActionMenu}
              menu={
                <PaymentActionMenu
                  handleClickDelete={() => setOpenDeleteModal(true)}
                  data={paymentActionClickedData}
                  id={id}
                />
              }
            />
          ) : (
            <NoEntitiesComponent text={'No Payment Method Found'} />
          )}
        </div>
      </CustomContainer>
    </>
  );
};

export default PaymentLocationTable;
