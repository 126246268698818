export const PATHS = {
  DEFAULT_LOGIN: '/auth/login',
  DEFAULT_HOME: '/',
  SIGN_UP: '/auth/sign-up',
  FORGET_PASSWORD: '/auth/forget-password',
  CHECK_EMAIL: '/auth/check-email',
  RESET_PASSWORD: '/auth/reset-password',
  SIGN_UP_STEPPER: '/auth/sign-up',
  THANK_YOU_PAGE: '/auth/thank-you',
  // ****************claims
  CLAIMS: '/claims',
  CLAIM_FILE: '/claims/claim-file',
  EDIT_CLAIM: '/claims/edit-claim',
  REPORT_MANUAL_CLAIM: '/claims/report-manual-claim',
  CONCLUDE_TRANSIT: '/claims/conclude-transit',
  CLAIM_DETAIL: '/claims/claim-detail',
  MANUAL_CLAIM_DETAIL: '/claims/manual-claim-detail',
  // *****************Shipments
  SHIPMENTS: '/shipments',
  REPORTS_SHIPMENTS: '/shipments/report-shipment',
  EDIT_SHIPMENT: '/shipments/edit-shipment',
  VIEW_SHIPMENT_DETAIL: '/shipments/view-shipment-detail',
  // *****************Shipments
  PENDING_SHIPMENTS: '/pending-shipments',
  // REPORTS_SHIPMENTS: "/shipments/report-shipment",
  // EDIT_SHIPMENT: "/shipments/edit-shipment",
  // ******************** ship from
  SHIP_FROM: '/ship-from',
  ADD_SHIP_FROM_ADDRESS: '/ship-from/add-ship-from-address',
  EDIT_SHIP_FROM_ADDRESS: '/ship-from/edit-ship-from-address',
  // *********************staff
  STAFF: '/staff',
  STAFF_ADD_STAFF_MEMBER: '/staff/add-staff-member',
  //************ payment ****************/
  PAYMENT: '/payment',
  PAYMENT_ADD_PAYMENT_METHOD_CARD: '/payment/add-payment-method/card',
  PAYMENT_SUCCESS: '/payment/success',
  //************ entities ****************/
  ENTITIES: '/entities',
  EDIT_ENTITIES: '/entities/edit-entity',
  DETAIL_ENTITIES: '/entities/details',
  SHIPMENT_ENTITY: '/entities/entity-shipment-view',
  PAYMENT_ENTITY: '/entities/entity-payments',
  PAYMENT_CARDS_ENTITY: '/entities/cards',
  // billing
  BILLING: '/billing',
  // profile
  PROFILE: '/profile',
  // admin
  ADMIN_LOGIN: '/auth/admin/login',
  ADMIN_HOME: '/admin',
  CARRIERS: '/carriers',
  SHIPMENT_TYPES: '/shipment-types',
  INDUSTRIES: '/industries',
  SALES_MEMBERS: '/sales-members',
  DASHBOARD: '/dashboard',
  GRAPHS: '/graphs',
};
