// LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Utility function for formatting numbers
const formatNumber = (num) => {
  if (num >= 1e6) return (num / 1e6).toFixed(2) + 'M';
  if (num >= 1e3) return (num / 1e3).toFixed(2) + 'k';
  return num.toString();
};

const LineChart = ({ chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'CPIC Data for 2024',
      },
      datalabels: {
        color: '#000', // Color for the data labels
        anchor: 'end',
        align: 'top',
        formatter: (value) => formatNumber(value), // Format data labels
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value) => formatNumber(value), // Format y-axis labels
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
