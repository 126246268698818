import { gql } from '@apollo/client';
import client from '../apollo.';

export const getAllEntitiesQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query AllEntity {
        allEntity {
          name
          emailAddress
          address
          EIN
          DBA
          id
          principalName
          phoneNumber
          since
          type {
            name
          }
          _count {
            entityMember
            location
          }
          salesPersonCode {
            firstName
            lastName
            id
          }
          rate
          internationalRate
          isBlocked
          isManualPayment
          website
          createdAt
          additionalNote
          policyDocument
        }
      }
    `,
  });
  return { data, errors };
};

export const removeEntityMutation = async (id) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation RemoveEntity($id: Int!) {
        removeEntity(id: $id) {
          id
        }
      }
    `,
    variables: {
      id: id,
    },
  });
  return { data, errors };
};

export const removeNewEntityMutation = async (name) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation RemoveApplication($name: String!) {
        removeApplication(name: $name) {
          success
          message
        }
      }
    `,
    variables: {
      name: name,
    },
  });

  return { data, errors };
};

export const getEntity = async (id) => {
  try {
    const { data, errors } = await client.query({
      query: gql`
        query entity($id: Int!) {
          entity(id: $id) {
            name
            id
            rate
            DBA
            EIN
            isBlocked
            address
            emailAddress
            phoneNumber
            since
            website
            rebateAddress
            rebateEmail
            createdAt
            additionalNote
            typeId
            type {
              name
            }
            salesPersonCode {
              firstName
              lastName
              id
            }
            rate
            internationalRate
            website
            createdAt
            additionalNote
            location {
              id
              name
              stripeCustomerID
              defaultPaymentMethodID
              address {
                id
                nickName
                address
              }
            }
            entityMember {
              id
              entityId
              firstName
              emailAddress
              createdAt
              lastName
              role
              id
              lastName
              phoneNumber
              termsAccepted
              additionalNote
              Shipment {
                confirmationId
                to
                paid
                pickUpDate
                totalValue
                isDelivered
                deliveryDate
                premium
                carrierId
                carrier {
                  name
                }
                locationId
                location {
                  id
                  name
                  address {
                    id
                    nickName
                    address
                  }
                }
              }
            }
            salesPersonCode {
              firstName
              lastName
              id
            }
            policyDocument
          }
        }
      `,

      variables: {
        id: id,
      },
    });
    return { data, errors };
  } catch (err) {
    console.error('ERR:', JSON.stringify(err));
  }
};

export const getEntityPaymentMethods = async (id) => {
  try {
    const { data, errors } = await client.query({
      query: gql`
        query GetPaymentMethodsByEntityId($id: Int!) {
          getPaymentMethodsByEntityId(id: $id) {
            id
            brand
            exp_month
            exp_year
            country
            last4
            created
            livemode
            type
          }
        }
      `,
      variables: {
        id: id,
      },
    });
    return { data, errors };
  } catch (err) {
    console.error('ERR:', JSON.stringify(err));
  }
};

export const updateEntityMutation = async (apiData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation UpdateEntity(
        $entityWhereUniqueInput: EntityWhereUniqueInput!
        $updateEntityInput: EntityUpdateInput!
      ) {
        updateEntity(
          entityWhereUniqueInput: $entityWhereUniqueInput
          updateEntityInput: $updateEntityInput
        ) {
          id
        }
      }
    `,
    variables: {
      entityWhereUniqueInput: {
        id: apiData.id,
      },
      updateEntityInput: {
        name: {
          set: apiData.businessName,
        },
        DBA: {
          set: apiData.dba,
        },
        EIN: {
          set: apiData.busniessTin,
        },
        address: {
          set: apiData.address,
        },
        phoneNumber: {
          set: apiData.phone ? apiData.phone : '',
        },
        emailAddress: {
          set: apiData.email,
        },
        since: {
          set: apiData.since ? apiData.since : '',
        },
        rate: {
          set: parseFloat(apiData.rate),
        },
        internationalRate: {
          set: parseFloat(apiData.internationalRate),
        },
        website: {
          set: apiData.website ? apiData.website : '',
        },
        rebateEmail: {
          set: apiData.emailRebate ? apiData.emailRebate : '',
        },
        rebateAddress: {
          set: apiData?.rebateAddress ? apiData?.rebateAddress : '',
        },
        additionalNote: {
          set: apiData.note,
        },
        salesPersonCode: {
          connect: {
            id: parseInt(apiData.salesPersonCode),
          },
        },
        policyDocument: {
          set: apiData.policyDocument,
        },
      },
    },
  });
  return { data, errors };
};

export const manualPaymentEntityMutation = async (id, manualPay) => {
  console.log(id, manualPay);
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation manualPay($id: Int!, $manualPay: Boolean!) {
        manualPay(id: $id, manualPay: $manualPay) {
          id
        }
      }
    `,
    variables: {
      id: id,
      manualPay: manualPay,
    },
  });
  return { data, errors };
};

export const suspendEntityMutation = async (id) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation SuspendEntity($id: Int!) {
        suspendEntity(id: $id) {
          id
        }
      }
    `,
    variables: {
      id: id,
    },
  });
  return { data, errors };
};
