import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import toast from 'react-hot-toast'; // Add toast import
import { useNavigate } from 'react-router-dom';
import { getListPaymentMethodsQuery } from '../../../api';
import { CustomButton } from '../../../common/custom';
import { PATHS } from '../../../constants/paths';
import useApi from '../../../hook/useApi';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_PK);

const StripeCardDetailsForm = ({
  setupIntent,
  onCloseStripeForm,
  refreshPaymentMethods,
}) => {
  const options = {
    clientSecret: setupIntent?.client_secret,
    appearance: {},
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm
        onCloseStripeForm={onCloseStripeForm}
        setupIntent={setupIntent}
        refreshPaymentMethods={refreshPaymentMethods}
      />
    </Elements>
  );
};

export default StripeCardDetailsForm;

const SetupForm = ({
  onCloseStripeForm,
  setupIntent,
  refreshPaymentMethods,
}) => {
  const [getListPaymentMethods] = useApi(getListPaymentMethodsQuery);

  // Get customer ID from setupIntent instead of localStorage
  const customerId = setupIntent?.customer;

  const fetchPaymentMethodsList = async () => {
    try {
      if (!customerId) {
        console.warn('Missing customer ID in setupIntent:', setupIntent);
        return;
      }

      const { errors } = await getListPaymentMethods(customerId);

      if (errors) {
        console.error('Error fetching payment methods:', errors);
      }
    } catch (error) {
      console.error('Error in fetchPaymentMethodsList:', error);
    }
  };

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [showWarning, setShowWarning] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }

    // Show loading toast
    toast.loading('Processing payment method...');

    try {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: 'if_required',
      });

      toast.dismiss(); // Clear loading toast

      if (error) {
        setErrorMessage(error.message);
        toast.error(error.message);
      } else {
        fetchPaymentMethodsList();
        toast.success('Payment method added successfully');

        // Extract entity ID from current URL
        const currentPath = window.location.pathname;
        const entityIdMatch = currentPath.match(/edit-entity\/(\d+)/);
        const entityId = entityIdMatch ? entityIdMatch[1] : null;

        if (entityId) {
          // Navigate to the entity edit page with the dynamic ID
          navigate(`/entities/edit-entity/${entityId}`);
        } else {
          // Fallback to entities page if ID not found
          navigate(PATHS.ENTITIES);
        }
        refreshPaymentMethods();
        onCloseStripeForm();
      }
    } catch (err) {
      // Error handling remains the same
      toast.dismiss();
      toast.error(err.message || 'An unexpected error occurred');
      setErrorMessage(err.message || 'An unexpected error occurred');
    }

    // Remove the automatic navigation that happens regardless of success/failure
  };

  return (
    <form className='mt-10' onSubmit={handleSubmit}>
      <PaymentElement
        onChange={(event) => {
          if (event.value.type.includes('card')) {
            setShowWarning(true);
          } else {
            setShowWarning(false);
          }
        }}
        className='px-2'
      />
      <div className=' flex flex-col  md:flex-row gap-[18px]  md:gap-[32px] justify-between mt-[32px]'>
        <CustomButton
          type='button'
          onClick={() => {
            navigate(PATHS.ENTITIES);
          }}
          className='order-1 md:order-none bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
          variant='outline'
          text='Cancel'
        />

        <CustomButton
          className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
          text='Save'
        />
      </div>
      {showWarning ? (
        <p className='py-6 text-xs font-semibold'>
          * Additional 3% on premium will be charged on Credit Card payments
        </p>
      ) : (
        ''
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
