import { createSlice } from '@reduxjs/toolkit';
import {
  addShipment,
  entityShipment,
  storeAllShipments,
} from './shipmentsAction';

const initialState = {
  allShipments: [],
  entityShipments: [],
  canceledShipments: [],
};

const formsSlice = createSlice({
  name: 'allShipments',
  initialState,
  reducers: {
    storeAllShipmentsAction: storeAllShipments,
    addShipmentAction: addShipment,
    entityShipmentAction: entityShipment,
    storeCanceledShipmentsAction: (state, action) => {
      state.canceledShipments = action.payload.CancelledShipments;
    },
    resetAllSHipmentsAction: () => {},
  },
});

export const shipmentsReducer = formsSlice.reducer;
export const {
  storeAllShipmentsAction,
  addShipmentAction,
  storeCanceledShipmentsAction,
  entityShipmentAction,
} = formsSlice.actions;
