import { Tooltip } from '@material-tailwind/react';
import React from 'react';

import {
  approvedIcon,
  attachement,
  checkIcon,
  deleteIcon,
  editIcon,
  paymentsMenuIcon,
} from '../../../../assets';
import { CustomText } from '../../../../common/custom';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../../common/customAssets';
import { viewIcon } from '../../../../assets';

import useShipmentsActionMenu from './useShipmentsActionMenu.hook';

const ShipmentsActionMenu = ({
  openDeleteModal,
  openRefundModal,
  data,
  openshipmentStatusModal,
  opensendPaymentReceiptModal,
  changeConfirmStatus,
  confirmPaymentStatus,
  upper,
  handleViewAttachments,
  refundIcon,
}) => {
  const { editHandler, viewHandler } = useShipmentsActionMenu();
  console.log(data, 'data?.premium in action')

  const disablePaymentReciept = data?.premium !== 'UNPAID' && data?.paid === false

  return (
    <div
      className={`w-[190px] z-10 shadow-md bg-foundational-white  absolute ${upper ? 'top-[-220px]' : 'top-[10px]'
        }  right-[50px] rounded-[8px] flex flex-col items-center justify-center  gap-[2px] py-[4px] border-[1px] border-solid border-[#EAECF0] `}
    >
      <div
        onClick={() => editHandler(data)}
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <Tooltip className='mt-[-10px] z-50' content='Edit'>
          <div>
            {' '}
            <CustomIcon icon={editIcon} />
          </div>
        </Tooltip>

        <CustomText className='font-inter font-[500] ml-[8px] '>
          <div>Edit</div>
        </CustomText>
      </div>
      <div
        onClick={() => viewHandler(data)}
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <Tooltip className='mt-[-10px] z-50' content='View'>
          <div>
            {' '}
            <CustomIcon icon={viewIcon} />
          </div>
        </Tooltip>

        <CustomText className='font-inter font-[500] ml-[8px] '>
          <div>View Details</div>
        </CustomText>
      </div>
      {(data?.documents ?? '[]').length === 0 &&
        (data?.deliveryDocuments ?? '[]').length === 0 ? (
        ''
      ) : (
        <div
          className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
          onClick={() => handleViewAttachments(data)}
        >
          <Tooltip className='mt-[-10px] z-50' content='Attachements'>
            <div>
              <CustomIcon icon={attachement} />
            </div>
          </Tooltip>
          <CustomText className='font-inter font-[500] ml-[8px]'>
            View Attachments
          </CustomText>
        </div>
      )}
      <div
        onClick={openshipmentStatusModal}
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <div className='flex'>
          <Tooltip className='mt-[-10px] z-50' content='Change Status'>
            <div>
              <CustomIcon icon={checkIcon} />
            </div>
          </Tooltip>
          <CustomText className='font-inter font-[500] ml-[8px]  '>
            Change Status
          </CustomText>
        </div>
      </div>
      <div
        onClick={() => disablePaymentReciept && opensendPaymentReceiptModal()}
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <div className={`flex ${!disablePaymentReciept && 'opacity-20'}`}>
          <Tooltip className='mt-[-10px] z-50' content='Send Payment Receipt'>
            <div>
              <CustomIcon icon={attachement} />
            </div>
          </Tooltip>
          <CustomText className='font-inter font-[500] ml-[8px]'>
            Send Payment Reciept
          </CustomText>
        </div>
      </div>
      <div
        onClick={() =>
          !data.confirmationId && changeConfirmStatus(parseInt(data.id))
        }
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <div className={`flex ${data.confirmationId && 'opacity-20'}`}>
          <Tooltip className='mt-[-10px] z-50' content='Confirmation'>
            <div>
              <CustomIcon icon={approvedIcon} />
            </div>
          </Tooltip>
          <CustomText
            className={`font-inter font-[500] ml-[8px] ${!data.confirmationId && 'text-[#228400]'
              }  `}
          >
            Confirm
          </CustomText>
        </div>
      </div>
      {data.paid ? (
        <CustomContainer className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '>
          <div className='flex  opacity-20'>
            <Tooltip className='mt-[-10px] z-50' content='Edit'>
              <div>
                <CustomIcon icon={checkIcon} />
              </div>
            </Tooltip>
            <CustomText className='font-inter font-[500] ml-[8px]  '>
              Change to Paid
            </CustomText>
          </div>
        </CustomContainer>
      ) : (
        <div
          onClick={() => confirmPaymentStatus(data.id)}
          className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
        >
          <div className='flex'>
            <Tooltip className='mt-[-10px] z-50' content='Edit'>
              <div>
                <CustomIcon icon={checkIcon} />
              </div>
            </Tooltip>
            <CustomText className='font-inter font-[500] ml-[8px]  '>
              Change to Paid
            </CustomText>
          </div>
        </div>
      )}
      {data?.isRefunded ? (
        <CustomContainer className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '>
          <div className='flex  opacity-20'>
            {refundIcon}
            <CustomText className='font-inter font-[500] ml-[8px]  '>
              Cancel It
            </CustomText>
          </div>
        </CustomContainer>
      ) : (
        <div
          onClick={openRefundModal}
          className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
        >
          <div className='flex'>
            {refundIcon}
            <CustomText className='font-inter font-[500] ml-[8px]  '>
              Cancel It
            </CustomText>
          </div>
        </div>
      )}
      <div
        onClick={openDeleteModal}
        className='cursor-pointer flex items-center  w-full min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px]'
      >
        <Tooltip className='mt-[-10px] z-50' content='Delete'>
          <div>
            {' '}
            <CustomIcon icon={deleteIcon} />
          </div>
        </Tooltip>
        <CustomText className='font-inter font-[500] ml-[8px]'>
          <div className='font-inter font-[500] text-[#D92D20] flex gap-[12px]'>
            Delete
          </div>
        </CustomText>
      </div>
    </div>
  );
};

export default ShipmentsActionMenu;
