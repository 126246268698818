// BarChart.js
import React, { useState, useEffect } from 'react';
import BarChart from './BarChart';
import LineChart from './LineChart';
import { getGraphData } from '../../api';
import { CustomButton } from '../../common/custom';

const Graphs = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [chartData, setChartData] = useState({
    labels: [], // Empty labels for now
    datasets: [
      {
        label: 'Revenue',
        data: [], // Empty data for now
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Shipment Count',
        data: [], // Empty data for now
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  });

  // Fetch data with optional date filters
  const fetchData = async (startDate = null, endDate = null) => {
    const data = await getGraphData(startDate, endDate);
    if (data) {
      const labels = data.map((item) => {
        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        return `${monthNames[item.month - 1]} ${item.year}`;
      });

      const revenueData = data.map((item) => item.totalRevenue);
      const shipmentsCountData = data.map((item) => item.shipmentsCount);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Revenue',
            data: revenueData,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            borderColor: 'rgba(75,192,192,1)',
            fill: true,
            tension: 0.4, // for a smooth line
          },
          {
            label: 'Shipments Count',
            data: shipmentsCountData,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
            fill: true,
            tension: 0.4, // for a smooth line
          },
        ],
      });
    }
  };

  // Load data without filters initially
  useEffect(() => {
    fetchData();
  }, []);

  // Handle date filter submission
  const handleFilter = () => {
    if (isFilterApplied) {
      setToDate('');
      setFromDate('');
      fetchData();
    } else {
      fetchData(fromDate, toDate); // Call API with date filters
      setIsFilterApplied(true);
    }
  };

  return (
    <div>
      <div style={{ margin: '12px 0' }}>
        <div className='flex justify-between'>
          <div className='flex justify-start items-center gap-4 p-2'>
            <h1 className='text-2xl font-semibold mb-6 text-foundation-brown'>
              Graphs
            </h1>
          </div>
          <div className='flex justify-end items-center gap-4 p-2'>
            <label>From: </label>
            <input
              className='flex h-12 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-background  border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] rounded-lg border-solid border-[#E7ECF4] bg-[#f8fafb]'
              type='date'
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <label>To: </label>
            <input
              className='flex h-12 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-background  border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] rounded-lg border-solid border-[#E7ECF4] bg-[#f8fafb]'
              type='date'
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />

            <CustomButton
              className='brown-bg-btn'
              text={isFilterApplied ? 'Clear Filters' : 'Apply Filters'}
              onClick={handleFilter}
            />
          </div>{' '}
        </div>
      </div>
      <BarChart chartData={chartData} />
      <LineChart chartData={chartData} />
    </div>
  );
};

export default Graphs;
