import LoginPage from './auth/LoginPage';
import CheckEmailPage from './auth/CheckEmailPage';
import ForgetPasswordPage from './auth/ForgetPasswordPage';
import ResetPasswordPage from './auth/ResetPasswordPage';
import SignUpStepperPage from './auth/SignUpStepperPage';
import ClaimsPage from './claims/ClaimsPage';
import ReportShipmentsPage from './shipments/ReportShipmentsPage';
import ShipmentsPage from './shipments/ShipmentsPage';
import EditShipmentPage from './shipments/EditShipmentPage';

import EntitiesPage from './entities/EntitiesPage';
import EditEntitiesPage from './entities/EditEntitiesPage';
import PendingShipmentsPage from './pending-shipments/pendingShipmentsPage';
import BillingPage from './billing/BillingPage';
import DashboardPage from './dashboard/DashboardPage';
import PrrofilePage from './profile';
import GraphsPage from './graphs';
import withAuth from './withAuth';

const ProtectedBillingPage = withAuth(BillingPage);
const ProtectedEntitiesPage = withAuth(EntitiesPage);
const ProtectedEditEntitiesPage = withAuth(EditEntitiesPage);
const ProtectedPendingShipmentsPage = withAuth(PendingShipmentsPage);
const ProtectedClaimsPage = withAuth(ClaimsPage);
const ProtectedReportShipmentsPage = withAuth(ReportShipmentsPage);
const ProtectedShipmentsPage = withAuth(ShipmentsPage);
const ProtectedEditShipmentPage = withAuth(EditShipmentPage);
const ProtectedProfilePage = withAuth(PrrofilePage);
const ProtectedDashboardPage = withAuth(DashboardPage);
const ProtectedGraphsPage = withAuth(GraphsPage);

export {
  LoginPage,
  ForgetPasswordPage,
  CheckEmailPage,
  ResetPasswordPage,
  SignUpStepperPage,
  // ClaimsPage,
  // ShipmentsPage,
  // ReportShipmentsPage,
  // EditShipmentPage,
  // PendingShipmentsPage,
  // EntitiesPage,
  // EditEntitiesPage,
  // BillingPage,
  // PrrofilePage,
  ProtectedClaimsPage as ClaimsPage,
  ProtectedShipmentsPage as ShipmentsPage,
  ProtectedReportShipmentsPage as ReportShipmentsPage,
  ProtectedEditShipmentPage as EditShipmentPage,
  ProtectedPendingShipmentsPage as PendingShipmentsPage,
  ProtectedEntitiesPage as EntitiesPage,
  ProtectedEditEntitiesPage as EditEntitiesPage,
  ProtectedBillingPage as BillingPage,
  ProtectedProfilePage as PrrofilePage,
  ProtectedDashboardPage as DashboardPage,
  ProtectedGraphsPage as GraphsPage,
};
