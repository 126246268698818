import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import { brownClose } from '../../../assets';
import {
    CustomButton,
    CustomHeading,
    CustomModal
} from '../../../common/custom';
import { CustomIcon } from '../../../common/customAssets';

const EntityManualPaymentModal = ({ closeManualPaymentModal, modalHeading, manualPaymentHandler, entity }) => {
    const [isManualPayment, setIsManualPayment] = useState(entity.isManualPayment)
    console.log(entity, 'entity.isManualPayment')
    const handleManualPayment = async () => {
        await manualPaymentHandler(entity?.id, isManualPayment)
    }
    return (
        <CustomModal>
            <div className='h-auto p-8 max-w-[600px] h-[250px] bg-pure-white rounded-[12px] flex flex-col items-center gap-1 justify-center px-4'>
                <div className='flex justify-between py-2 max-w-[476px] w-full h-[50px]'>
                    <div className=' gap-1 flex '>
                        <div className=' max-w-[400px]'>
                            <CustomHeading className='text-[26px] font-inter leading-[28px] font-[600] text-foundation-brown'>
                                {modalHeading}
                            </CustomHeading>
                        </div>
                    </div>
                    <div
                        onClick={closeManualPaymentModal}
                        className=' cursor-pointer flex justify-end'
                    >
                        <CustomIcon
                            icon={brownClose}
                            className=' h-[18px] w-[18px] flex justify-center items-center'
                        />
                    </div>
                </div>
                <div className='min-w-[360px]'>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isManualPayment}
                                onChange={(e) => setIsManualPayment(e.target.checked)}
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: 'rgb(119, 85, 61)', // Toggle thumb color when active
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: 'rgb(119, 85, 61)', // Track color when active
                                    },
                                    '& .MuiSwitch-track': {
                                        backgroundColor: '#ccc', // Default track color
                                    },
                                }} />
                        }
                        label="Manual Payment"
                    />
                    <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
                        <CustomButton
                            type='button'
                            onClick={closeManualPaymentModal}
                            className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
                            variant='outline'
                            text='Cancel'
                        />
                        <CustomButton
                            onClick={handleManualPayment}
                            className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
                            text='Update'
                        />
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default EntityManualPaymentModal;
