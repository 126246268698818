import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { PATHS } from './constants/paths';

// import CreateClaim from './features/claims/create/create-claim.component';
// import EditClaim from './features/claims/edit/edit-claim.component';
import PrivateRoute from './features/private';
// import Shipments from './features/shipments/shipments';
import Navbar from './layout/navbar/Navbar';
import {
  CheckEmailPage,
  ForgetPasswordPage,
  LoginPage,
  ResetPasswordPage,
  SignUpStepperPage,
} from './pages';

import ThankYouPage from './features/auth/thank-you-page/thank-you-page.component';
import Footer from './layout/footer';
import PageNotFound from './pages/NotFoundPage';
// import ConcludeTransit from './features/claims/components/concludeTransit/conclude-transit';
// import ClaimDetails from './features/claims/detail/claim-detail.component';
// import { EntityShipment } from './features/entities';
// import PaymentLocationTable from './features/entities/EntityPaymentTable/paymentLocationTable';
// import Entity_Card_View from './features/entities/EntityPaymentTable/entity_Card_View';
// import ViewShipmentDetailPage from './pages/shipments/ViewShipmentDetailPage';
// import CarriersPage from './pages/carriers/CarriersPage';
// import IndustriesPage from './pages/industries/IndustriesPage';
// import ShipmentTypesPage from './pages/shipment-types/ShipmentTypesPage';
// import SalesMembersPage from './pages/sales-person/SalesPersonPage';
// import ReportManualClaim from './features/claims/report-manual-claim/create-claim.component';
// import ManualClaimDetails from './features/claims/detail/manual-claim-detail.component';
// import PaymentMethodSuccess from './features/stripForm/PaymentMethodSuccess';
// import TradeShowPropertyCoverage from './pages/trade-show-property-coverage';
// Lazy-loaded components
const DashboardPage = lazy(() => import('./pages/dashboard/DashboardPage'));
const GraphsPage = lazy(() => import('./pages/graphs'));
const BillingPage = lazy(() => import('./pages/billing/BillingPage'));
const ClaimsPage = lazy(() => import('./pages/claims/ClaimsPage'));
const EditEntitiesPage = lazy(() =>
  import('./pages/entities/EditEntitiesPage')
);
const EditShipmentPage = lazy(() =>
  import('./pages/shipments/EditShipmentPage')
);
const EntitiesPage = lazy(() => import('./pages/entities/EntitiesPage'));
const PendingShipmentsPage = lazy(() =>
  import('./pages/pending-shipments/pendingShipmentsPage')
);
const CancelShipmentsPage = lazy(() => import('./pages/cancel-shipments'));
const PrrofilePage = lazy(() => import('./pages/profile'));
const ReportShipmentsPage = lazy(() =>
  import('./pages/shipments/ReportShipmentsPage')
);
const EntityShipmentPage = lazy(() =>
  import('./pages/entities/EntityShipment')
);
const CarriersPage = lazy(() => import('./pages/carriers/CarriersPage'));
const IndustriesPage = lazy(() => import('./pages/industries/IndustriesPage'));
const ShipmentTypesPage = lazy(() =>
  import('./pages/shipment-types/ShipmentTypesPage')
);
const SalesMembersPage = lazy(() =>
  import('./pages/sales-person/SalesPersonPage')
);
const TradeShowPropertyCoverage = lazy(() =>
  import('./pages/trade-show-property-coverage')
);

const CreateClaim = lazy(() =>
  import('./features/claims/create/create-claim.component')
);
const EditClaim = lazy(() =>
  import('./features/claims/edit/edit-claim.component')
);
const ClaimDetails = lazy(() =>
  import('./features/claims/detail/claim-detail.component')
);
const ConcludeTransit = lazy(() =>
  import('./features/claims/components/concludeTransit/conclude-transit')
);
const ReportManualClaim = lazy(() =>
  import('./features/claims/report-manual-claim/create-claim.component')
);
const ManualClaimDetails = lazy(() =>
  import('./features/claims/detail/manual-claim-detail.component')
);

const Shipments = lazy(() => import('./features/shipments/shipments'));
const PaymentLocationTable = lazy(() =>
  import('./features/entities/EntityPaymentTable/paymentLocationTable')
);
const Entity_Card_View = lazy(() =>
  import('./features/entities/EntityPaymentTable/entity_Card_View')
);
const ViewShipmentDetailPage = lazy(() =>
  import('./pages/shipments/ViewShipmentDetailPage')
);
const PaymentMethodSuccess = lazy(() =>
  import('./features/stripForm/PaymentMethodSuccess')
);

// Loading component for Suspense fallback
const LoadingSpinner = () => (
  <div className='flex items-center justify-center min-h-[50vh]'>
    <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-primary'></div>
  </div>
);

const App = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const location = useLocation();
  const authPage = location.pathname.split('/')[1] === 'auth';
  const defaultRoute = location.pathname === '/';

  return (
    <div className='flex flex-col justify-between   h-[100vh]'>
      {defaultRoute && token ? (
        <Navigate to={PATHS.SHIPMENTS} replace />
      ) : defaultRoute ? (
        <Navigate to={PATHS.DEFAULT_LOGIN} replace />
      ) : null}
      {authPage ? null : <Navbar />}
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route exact path={PATHS.DEFAULT_LOGIN} element={<LoginPage />} />
          <Route
            exact
            path={PATHS.FORGET_PASSWORD}
            element={<ForgetPasswordPage />}
          />
          <Route exact path={PATHS.CHECK_EMAIL} element={<CheckEmailPage />} />
          <Route
            exact
            path={PATHS.RESET_PASSWORD}
            element={<ResetPasswordPage />}
          />
          <Route
            exact
            path={PATHS.SIGN_UP_STEPPER}
            element={<SignUpStepperPage />}
          />
          <Route exact path={PATHS.THANK_YOU_PAGE} element={<ThankYouPage />} />
          {/*******************************************************/}
          {/************************ Claims ***********************/}
          {/*******************************************************/}
          <Route
            exact
            path={PATHS.CLAIMS}
            element={
              <PrivateRoute>
                <ClaimsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.REPORT_MANUAL_CLAIM}
            element={
              <PrivateRoute>
                <ReportManualClaim />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.CLAIM_FILE}
            element={
              <PrivateRoute>
                <CreateClaim />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.EDIT_CLAIM}/:id`}
            element={
              <PrivateRoute>
                <EditClaim />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.CONCLUDE_TRANSIT}`}
            element={
              <PrivateRoute>
                <ConcludeTransit />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.CLAIM_DETAIL}/:id`}
            element={
              <PrivateRoute>
                <ClaimDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.MANUAL_CLAIM_DETAIL}/:id`}
            element={
              <PrivateRoute>
                <ManualClaimDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.TRADE_SHOW_CLAIM_DETAIL}/:id`}
            element={
              <PrivateRoute>
                <ManualClaimDetails />
              </PrivateRoute>
            }
          />
          {/*******************************************************/}
          {/********************** Shipments **********************/}
          {/*******************************************************/}
          <Route
            exact
            path={PATHS.SHIPMENTS}
            element={
              <PrivateRoute>
                <Shipments />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.REPORTS_SHIPMENTS}
            element={
              <PrivateRoute>
                <ReportShipmentsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.CANCEL_SHIPMENTS}
            element={
              <PrivateRoute>
                <CancelShipmentsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.EDIT_SHIPMENT}/:id`}
            element={
              <PrivateRoute>
                <EditShipmentPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.VIEW_SHIPMENT_DETAIL}/:id`}
            element={
              <PrivateRoute>
                <ViewShipmentDetailPage />
              </PrivateRoute>
            }
          />
          {/*******************************************************/}
          {/****************** Pending Shipments ******************/}
          {/*******************************************************/}
          <Route
            exact
            path={PATHS.PENDING_SHIPMENTS}
            element={
              <PrivateRoute>
                <PendingShipmentsPage />
              </PrivateRoute>
            }
          />
          {/*******************************************************/}
          {/*********************** Entities **********************/}
          {/*******************************************************/}
          <Route
            exact
            path={PATHS.ENTITIES}
            element={
              <PrivateRoute>
                <EntitiesPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.EDIT_ENTITIES}`}
            element={
              <PrivateRoute>
                <EditEntitiesPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.EDIT_ENTITIES}/:id`}
            element={
              <PrivateRoute>
                <EditEntitiesPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.DETAIL_ENTITIES}/:id`}
            element={
              <PrivateRoute>
                <EditEntitiesPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.PAYMENT_ENTITY}/:id`}
            element={
              <PrivateRoute>
                <PaymentLocationTable />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.PAYMENT_CARDS_ENTITY}/`}
            element={
              <PrivateRoute>
                <Entity_Card_View />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.PAYMENT_SUCCESS}
            element={
              <PrivateRoute>
                <PaymentMethodSuccess />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.SHIPMENT_ENTITY}/:id`}
            element={
              <PrivateRoute>
                <EntityShipmentPage />
              </PrivateRoute>
            }
          />
          {/*******************************************************/}
          {/*********************** Billing ***********************/}
          {/*******************************************************/}
          <Route
            exact
            path={PATHS.BILLING}
            element={
              <PrivateRoute>
                <BillingPage />
              </PrivateRoute>
            }
          />
          {/*******************************************************/}
          {/********************** Dashboard **********************/}
          {/*******************************************************/}
          <Route
            exact
            path={PATHS.DASHBOARD}
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          {/*******************************************************/}
          {/*********************** Profile ***********************/}
          {/*******************************************************/}
          <Route
            exact
            path={PATHS.PROFILE}
            element={
              <PrivateRoute>
                <PrrofilePage />
              </PrivateRoute>
            }
          />
          {/*******************************************************/}
          {/********************* Admin Routes ********************/}
          {/*******************************************************/}
          <Route
            exact
            path={PATHS.CARRIERS}
            element={
              <PrivateRoute>
                <CarriersPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.INDUSTRIES}
            element={
              <PrivateRoute>
                <IndustriesPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.SHIPMENT_TYPES}
            element={
              <PrivateRoute>
                <ShipmentTypesPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.SALES_MEMBERS}
            element={
              <PrivateRoute>
                <SalesMembersPage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path={PATHS.GRAPHS}
            element={
              <PrivateRoute>
                <GraphsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.TRADESHOWPOLICY}
            element={
              <PrivateRoute>
                <TradeShowPropertyCoverage />
              </PrivateRoute>
            }
          />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
};

export default App;
