import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { createSetupIntentMutation, getEntity } from '../../../api';
import { getEntityPaymentMethods } from '../../../api/services/entities.service';
import { removePaymentMethodMutation } from '../../../api/services/paymentMethods.service';
import { paymentMasterIcon, paymentVisaIcon } from '../../../assets';
import { CustomIcon } from '../../../common/customAssets';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { ALL_UPPER_CASE } from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import useOutsideClick from '../../../hook/useOutsideClick';
import { storeSingleEntityAction } from '../../../store';

const UsePaymentEntity = () => {
  const [addressesLocation, setAddressesLocation] = useState([]);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  const [paymentActionClickedData, setPaymentActionClickedData] =
    useState(null);
  const [toggleCardActionMenu, setToggleCardActionMenu] = useState(null);
  const [entityName, setEntityName] = useState('');
  const [cardInformation, setCardInformation] = useState([]);
  const [openStripeForm, setOpenStripeForm] = useState(false);
  const [setupIntent, setSetupIntent] = useState(null);
  const [getSingleEntity] = useApi(getEntity, storeSingleEntityAction);
  const [getPaymentMethods] = useApi(getEntityPaymentMethods);

  const [createSetup] = useApi(createSetupIntentMutation);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState(null);

  const { id } = useParams();

  const navigate = useNavigate();

  const actionMenuRef = useRef(null);
  const actionCardMenuRef = useRef(null);

  const path = window.location.pathname;

  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));
  useOutsideClick(actionCardMenuRef, () => setToggleCardActionMenu(false));

  useEffect(() => {
    getData(parseInt(id));
  }, []);

  // Add a function to refresh payment methods
  const refreshPaymentMethods = () => {
    getData(parseInt(id));
  };

  const renderCardTypeIcon = (cardType) => {
    if (cardType === 'visa') {
      return <CustomIcon icon={paymentVisaIcon} />;
    }
    if (cardType === 'mastercard') {
      return <CustomIcon icon={paymentMasterIcon} />;
    }
  };

  const getData = async (id) => {
    try {
      const result = await getSingleEntity(id);
      setEntityData(result?.data?.entity);
      const response = await getPaymentMethods(id);
      if (response.data) {
        const cardsData = response.data?.getPaymentMethodsByEntityId.map(
          (card, index) => {
            return {
              id: card?.id,
              type: (
                <div className='bg-[#E6EAFF] text-[#314EEB] rounded-[50px] font-inter font-[500] text-sm  w-[56px] min-h-[28px] flex justify-center items-center'>
                  {ALL_UPPER_CASE(card.type)}
                </div>
              ),
              provider: (
                <div className='flex items-center gap-[8px]'>
                  {renderCardTypeIcon(card.brand)}
                </div>
              ),
              paymentMethod: `**** **** **** ${card?.last4}`,
              expiration: card?.exp_month + '/' + card?.exp_year,
              // default:
              //   result.data?.entity?.location[0]?.defaultPaymentMethodID ===
              //   card.id ? (
              //     <CustomButton
              //       variant='text'
              //       className='flex items-center cursor-normal bg-[#314EEB] text-[#fff] text-sm not-italic font-medium leading-[normal] px-3 py-2 rounded-[50px]'
              //       text='Default'
              //     />
              //   ) : (
              //     <CustomButton
              //       variant='outlined'
              //       size='sm'
              //       text='Set Primary'
              //       className='flex items-center bg-[#fff2e9] text-[#E55F00] text-sm not-italic font-medium leading-[normal] px-3 py-2 rounded-[50px]'
              //     />
              //   ),
              // action: (
              //   <CustomButton
              //     variant='outlined'
              //     size='sm'
              //     text='Delete'
              //     className='flex items-center bg-[#fff2e9] text-[#E55F00] text-sm not-italic font-medium leading-[normal] px-3 py-2 rounded-[50px]'
              //   />
              // ),
            };
          }
        );

        setPaymentMethods(cardsData);
      }
    } catch (e) {
      toast.error(JSON.stringify(e), {
        duration: TOAST_TIME,
      });
    }
  };

  const getSetupIntent = async () => {
    toast.dismiss();
    try {
      const result = await createSetup(
        entityData?.location[0]?.stripeCustomerID
      );
      // console.log(2, result);
      if (result.data) {
        setSetupIntent(result.data?.createSetupIntent);
        setOpenStripeForm(true);
      }
    } catch (e) {
      toast.error(JSON.stringify(e), {
        duration: TOAST_TIME,
      });
    }
  };

  const handleActionClick = async (e, data) => {
    if (e) e.stopPropagation();
    setPaymentActionClickedData(data);
    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
  };

  const handleDeleteCard = async () => {
    try {
      const response = await removePaymentMethodMutation(
        paymentActionClickedData.id
      );
      if (response?.data) {
        setToggleCardActionMenu(null);
        setOpenDeleteModal(false);

        const updatedPaymentMethods = paymentMethods.filter(
          (method) => method.id !== paymentActionClickedData?.id
        );
        setPaymentMethods(updatedPaymentMethods);
        toast.success('Payment Method deleted successfully', {
          duration: TOAST_TIME,
        });
      } else {
        toast.error('Something went wrong', { duration: TOAST_TIME });
      }
    } catch (error) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    } finally {
      setToggleCardActionMenu(null);
      setOpenDeleteModal(false);
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onCloseStripeForm = () => setOpenStripeForm(false);

  return {
    addressesLocation,
    onClickBack,
    handleActionClick,
    actionMenuRef,
    toggleActionMenu,
    paymentActionClickedData,
    entityName,
    id,
    cardInformation,
    handleDeleteCard,
    actionCardMenuRef,
    toggleCardActionMenu,
    isCardPage: path.includes('cards'),
    getSetupIntent,
    openStripeForm,
    setupIntent,
    onCloseStripeForm,
    paymentMethods,
    openDeleteModal,
    setOpenDeleteModal,
    refreshPaymentMethods,
  };
};

export default UsePaymentEntity;
