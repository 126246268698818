import { gql } from '@apollo/client';
import client from '../apollo.';

export const getGraphData = async (fromDate, toDate) => {
  const { data, errors } = await client.query({
    query: gql`
      query getChartData($fromDate: String, $toDate: String) {
        getChartData(fromDate: $fromDate, toDate: $toDate) {
          month
          year
          totalRevenue
          shipmentsCount
        }
      }
    `,
    variables: { fromDate, toDate },
  });

  if (errors) {
    console.error('Error fetching chart data', errors);
  }
  return data?.getChartData || [];
};
