import React from 'react';
import { CustomButton, CustomHeading } from '../../../../common/custom';
import CustomContainer from '../../../../common/custom/CustomContainer';
import ALlClaimsList from '../allClaimsList';
import { claimFile, conclude, plusBrown } from '../../../../assets';
import { useDispatch } from 'react-redux';
import { setPage } from '../../../../store/slices/paginationSlice';
import NoClaimsComponent from '../noCLaimsComponent';

const AllClaims = ({
  data,
  isLoading,
  reportClaimHandler,
  concludeHandler,
  newClaimHandler,
  currentTab,
  handleTabSwitch,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
        <CustomContainer className='px-6 min-h-[75px] flex justify-between items-center '>
          <div className='flex w-auto justify-end'>
            <div
              className={`p-2 mr-2 flex w-[150px] justify-center items-center text-small-para font-[500] text-filter-heading cursor-pointer ${currentTab === 'claims'
                ? 'bg-brown-background text-foundation-brown rounded-md'
                : 'text-filter-heading'
                }`}
              onClick={() => { handleTabSwitch('claims'); dispatch(setPage({ tableId: "/claims", page: 1 })); }}
            >
              Shipment Claims
            </div>
            <div
              className={`p-2 mr-2 flex w-[150px] justify-center items-center text-small-para font-[500] text-filter-heading cursor-pointer ${currentTab === 'manual'
                ? 'bg-brown-background text-foundation-brown rounded-md'
                : 'text-filter-heading'
                }`}
              onClick={() => { handleTabSwitch('manual'); dispatch(setPage({ tableId: "/manual-claims", page: 1 })); }}
            >
              Manual Claims{' '}
            </div>
            <div
              className={`p-2 mr-2 flex w-[180px] justify-center items-center text-small-para font-[500] text-filter-heading cursor-pointer ${currentTab === 'tradeShow'
                ? 'bg-brown-background text-foundation-brown rounded-md'
                : 'text-filter-heading'
                }`}
              onClick={() => { handleTabSwitch('tradeShow'); dispatch(setPage({ tableId: "/trade-show-claims", page: 1 })); }}
            >
              Trade Show Claims{' '}
            </div>
          </div>
          <div className='flex'>
            <div className='min-w-[168px]'>
              <CustomButton
                onClick={reportClaimHandler}
                className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] text-[#101828] flex items-center h-[48px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
                text='Report Manual'
                icon={plusBrown}
              />
            </div>
            <div className=' min-w-[140px]'>
              <CustomButton
                onClick={newClaimHandler}
                className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] text-[#101828] flex items-center h-[48px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
                text='Shipment Claim'
                icon={claimFile}
              />
            </div>
          </div>
        </CustomContainer>
        {(data?.length === 0 && !isLoading) ?
          <NoClaimsComponent>
            {/* <ClientsPanelHeader /> */}
          </NoClaimsComponent>

          : <div className=' overflow-x-auto'>
            <ALlClaimsList
              data={data}
              isLoading={isLoading}
              currentTab={currentTab}
            />
          </div>
        }
      </div>
    </>
  );
};

export default AllClaims;
