import React from 'react';
import { ClientsPanelHeader, CustomHeading } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { SHIPMENTS } from '../../../constants/columns';
import ChangeShipmentStatusModal from '../../modals/change-shipment-status-modal';
import DeleteModal from '../../modals/delete-modal';
import { NoShipmentsComponent } from '../components';
import ShipmentsActionMenu from '../components/shipmentsActionMenu';
import { useShipments } from './useShipments';
import { CustomButton } from '../../../common/custom';
import { crossCircle, filterIcon, plusGray } from '../../../assets';
import FilterShipmentModal from '../components/filterShipment';
import ViewAttachmentsModal from './viewAttachmentsModal';
import CircularILoader from '../../../common/custom/circularLoader';
import ApproveModal from '../../modals/approve-modal';

const Shipments = () => {
  const {
    data,
    open,
    handleClose,
    filteredData,
    handleViewAttachments,
    sendPaymentReceipt,
    refundHandler,
    cancelShipmentHandler,
    openRefundModal,
    closeRefundModal,
    showRefundModal,
    closeDeleteModal,
    shipmentActionClickedId,
    actionMenuRef,
    toggleActionMenu,
    shipmentActionClickedData,
    openDeleteModal,
    handleActionClick,
    showDeleteModal,
    showShipmentStatusModal,
    sendPaymentReceiptModal,
    opensendPaymentReceiptModal,
    openshipmentStatusModal,
    closeShipmentStatusModal,
    approveShipmentHandler,
    changeConfirmStatus,
    confirmPaymentStatus,
    showFilterModal,
    openFilterModal,
    closeFilterModal,
    entityOptions,
    register,
    handleSubmit,
    onSubmit,
    setValue,
    errors,
    onReset,
    attachments,
    user,
    reportShipmentHandler,
    loading,
  } = useShipments();

  // if (!loading && data?.length === 0) {
  //   return (
  //     <NoShipmentsComponent>
  //       <ClientsPanelHeader />
  //     </NoShipmentsComponent>
  //   );
  // }

  const refundIcon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='currentColor'
      className='w-5 h-5'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M8.25 9.75h4.875a2.625 2.625 0 0 1 0 5.25H12M8.25 9.75 10.5 7.5M8.25 9.75 10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185Z'
      />
    </svg>
  );
  // const shipmentsData = data?.sort((a, b) => Number(b?.id) - Number(a?.id));
  // console.log(shipmentsData, 'shipmentsData')
  // console.log(data, 'data')
  return (
    <>
      {showRefundModal && (
        <DeleteModal
          modalHeading='Cancel Shipment'
          modalPara='Are you sure you want to cancel this Shipment? This action cannot be undone.'
          closeDeleteModal={closeRefundModal}
          deleteHandler={() => refundHandler(shipmentActionClickedId)}
          modal='refund'
          icon={refundIcon}
        />
      )}
      {/* {sendPaymentReceiptModal &&
        <ApproveModal
          approvalHandler={sendPaymentReceipt(shipmentActionClickedId)}
          closeApproveModal={opensendPaymentReceiptModal}
          modalHeading="Send payment Receipt"
          modalPara="Do you want to send payment receipt ?"
          buttonText="Send Receipt"
        />
      } */}
      {showShipmentStatusModal ? (
        <ChangeShipmentStatusModal
          closeShipmentStatusModal={closeShipmentStatusModal}
          modalHeading='Change Shipment Status'
          shipmentActionClickedData={shipmentActionClickedData}
          approveShipmentHandler={approveShipmentHandler}
        />
      ) : null}
      {showDeleteModal ? (
        <DeleteModal
          modalHeading='Delete Shipment?'
          modalPara='Are you sure you want to delete this Shipment? This action cannot be undone.'
          deleteHandler={() => cancelShipmentHandler(shipmentActionClickedId)}
          closeDeleteModal={closeDeleteModal}
        />
      ) : showFilterModal ? (
        <FilterShipmentModal
          closeFilterModal={closeFilterModal}
          onReset={onReset}
          errors={errors}
          entityData={entityOptions}
          register={register}
          setValue={setValue}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      ) : null}

      <CustomContainer>
        {loading ? (
          <>
            {/* <ClientsPanelHeader /> */}
            <div class='flex justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl'>
              <CircularILoader />
            </div>
          </>
        ) : data?.length == 0 ? (
          <NoShipmentsComponent>
            <ClientsPanelHeader />
          </NoShipmentsComponent>
        ) : (
          <>
            <div className='flex justify-between'>
              <ClientsPanelHeader />
              {/* {user?.type === 'ADMIN' && ( */}
              <div className=' flex flex-col sm:flex-row justify-between items-start sm:items-center my-[24px]   sm:my-[30px] md:my-[36px] lg:my-[42px] '>
                <CustomButton
                  onClick={() => reportShipmentHandler()}
                  icon={plusGray}
                  text='Report Shipment'
                  className={`${''
                    // hideReportButton && 'hidden'
                    } bg-foundation-white px-[16px]  rounded-[8px] border border-[1px]  border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500] mt-4 sm:mt-0  gap-3`}
                />
              </div>
              {/* )} */}
            </div>
            <div className='border-2 border-[#EAECF0] rounded-[7px]'>
              <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
                <div className='flex justify-between items-center flex-row w-full'>
                  <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
                    All Shipments
                  </CustomHeading>
                  {!filteredData ?
                    <CustomButton
                      onClick={openFilterModal}
                      className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] text-[#101828] flex items-center h-[48px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
                      text='Filter'
                      icon={filterIcon}
                    /> : <CustomButton
                      onClick={onReset}
                      // onClick={() => setIsFilterCleared(true)}
                      className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] min-w-[140px] text-[#101828] flex items-center h-[48px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
                      text='Clear Filters'
                      icon={crossCircle}
                    />}
                </div>
              </CustomContainer>
              {open && (
                <ViewAttachmentsModal
                  onClose={handleClose}
                  title='View Attachments'
                  files={attachments}
                />
              )}
              <div className=' overflow-x-auto'>
                <CustomTable
                  header={SHIPMENTS}
                  rows={data}
                  handleActionClick={handleActionClick}
                  toggleActionMenu={toggleActionMenu}
                  actionMenuRef={actionMenuRef}
                  menu={
                    <ShipmentsActionMenu
                      openshipmentStatusModal={openshipmentStatusModal}
                      opensendPaymentReceiptModal={opensendPaymentReceiptModal}
                      data={shipmentActionClickedData}
                      openRefundModal={openRefundModal}
                      openDeleteModal={openDeleteModal}
                      changeConfirmStatus={changeConfirmStatus}
                      confirmPaymentStatus={confirmPaymentStatus}
                      handleViewAttachments={handleViewAttachments}
                      refundIcon={refundIcon}
                    />
                  }
                />
              </div>
            </div>
          </>
        )}
      </CustomContainer>
    </>
  );
};

export default Shipments;
