// // // BarChart.js
// // import React, { useEffect, useState } from 'react';
// // import { Bar } from 'react-chartjs-2';
// // import {
// //   Chart as ChartJS,
// //   CategoryScale,
// //   LinearScale,
// //   BarElement,
// //   Title,
// //   Tooltip,
// //   Legend,
// // } from 'chart.js';
// // import { getGraphData } from '../../api';

// // ChartJS.register(
// //   CategoryScale,
// //   LinearScale,
// //   BarElement,
// //   Title,
// //   Tooltip,
// //   Legend
// // );

// // const BarChart = () => {
// //   const [fromDate, setFromDate] = useState('');
// //   const [toDate, setToDate] = useState('');

// //   const [chartData, setChartData] = useState({
// //     labels: [], // Empty labels for now
// //     datasets: [
// //       {
// //         label: 'Revenue in 2024',
// //         data: [], // Empty data for now
// //         backgroundColor: 'rgba(53, 162, 235, 0.5)',
// //       },
// //     ],
// //   });

// //   // Fetch data with optional date filters
// //   const fetchData = async (startDate = null, endDate = null) => {
// //     const data = await getGraphData(startDate, endDate);
// //     if (data) {
// //       const labels = data.map((item) => {
// //         const monthNames = [
// //           'January',
// //           'February',
// //           'March',
// //           'April',
// //           'May',
// //           'June',
// //           'July',
// //           'August',
// //           'September',
// //           'October',
// //           'November',
// //           'December',
// //         ];
// //         return `${monthNames[item.month - 1]} ${item.year}`;
// //       });

// //       const revenueData = data.map((item) => item.totalRevenue);

// //       setChartData({
// //         labels,
// //         datasets: [
// //           {
// //             label: 'Revenue in 2024',
// //             data: revenueData,
// //             backgroundColor: 'rgba(53, 162, 235, 0.5)',
// //           },
// //         ],
// //       });
// //     }
// //   };

// //   // Load data without filters initially
// //   useEffect(() => {
// //     fetchData();
// //   }, []);

// //   // Handle date filter submission
// //   const handleFilter = () => {
// //     fetchData(fromDate, toDate); // Call API with date filters
// //   };

// //   const options = {
// //     responsive: true,
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //       },
// //       title: {
// //         display: true,
// //         text: 'CPIC Data for 2024',
// //       },
// //     },
// //   };

// //   return (
// //     <div>
// //       <div style={{ marginBottom: '20px' }}>
// //         <label>From: </label>
// //         <input
// //           type='date'
// //           value={fromDate}
// //           onChange={(e) => setFromDate(e.target.value)}
// //         />
// //         <label>To: </label>
// //         <input
// //           type='date'
// //           value={toDate}
// //           onChange={(e) => setToDate(e.target.value)}
// //         />
// //         <button onClick={handleFilter}>Apply Filters</button>
// //       </div>
// //       {chartData && <Bar data={chartData} options={options} />};
// //     </div>
// //   );
// // };

// // export default BarChart;

// import React, { useEffect, useState } from 'react';
// import { Bar } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ChartOptions,
// } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import data labels plugin
// import { getGraphData } from '../../api';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ChartDataLabels // Register the data labels plugin
// );

// const BarChart = () => {
//   const [fromDate, setFromDate] = useState('');
//   const [toDate, setToDate] = useState('');

//   const [chartData, setChartData] = useState({
//     labels: [], // Empty labels for now
//     datasets: [
//       {
//         label: 'Revenue in 2024',
//         data: [], // Empty data for now
//         backgroundColor: 'rgba(53, 162, 235, 0.5)',
//       },
//     ],
//   });

//   // Fetch data with optional date filters
//   const fetchData = async (startDate = null, endDate = null) => {
//     const data = await getGraphData(startDate, endDate);
//     if (data) {
//       const labels = data.map((item) => {
//         const monthNames = [
//           'January',
//           'February',
//           'March',
//           'April',
//           'May',
//           'June',
//           'July',
//           'August',
//           'September',
//           'October',
//           'November',
//           'December',
//         ];
//         return `${monthNames[item.month - 1]} ${item.year}`;
//       });

//       const revenueData = data.map((item) => item.totalRevenue);

//       setChartData({
//         labels,
//         datasets: [
//           {
//             label: 'Revenue in 2024',
//             data: revenueData,
//             backgroundColor: 'rgba(53, 162, 235, 0.5)',
//           },
//         ],
//       });
//     }
//   };

//   // Load data without filters initially
//   useEffect(() => {
//     fetchData();
//   }, []);

//   // Handle date filter submission
//   const handleFilter = () => {
//     fetchData(fromDate, toDate); // Call API with date filters
//   };

//   // Options for the chart
//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       title: {
//         display: true,
//         text: 'CPIC Data for 2024',
//       },
//       tooltip: {
//         callbacks: {
//           // Format tooltip label values
//           label: (context) => {
//             let value = context.raw;
//             return `Revenue: ${
//               value >= 1e6 ? (value / 1e6).toFixed(2) + 'M' : value
//             }`;
//           },
//         },
//       },
//       datalabels: {
//         display: true,
//         color: 'black',
//         anchor: 'end',
//         align: 'end',
//         formatter: (value) => {
//           // Format label values
//           return value >= 1e6 ? (value / 1e6).toFixed(2) + 'M' : value;
//         },
//       },
//     },
//     scales: {
//       y: {
//         ticks: {
//           // Format y-axis labels
//           callback: (value) => {
//             return value >= 1e6 ? (value / 1e6).toFixed(2) + 'M' : value;
//           },
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <div style={{ marginBottom: '20px' }}>
//         <label>From: </label>
//         <input
//           type='date'
//           value={fromDate}
//           onChange={(e) => setFromDate(e.target.value)}
//         />
//         <label>To: </label>
//         <input
//           type='date'
//           value={toDate}
//           onChange={(e) => setToDate(e.target.value)}
//         />
//         <button onClick={handleFilter}>Apply Filters</button>
//       </div>
//       {chartData && <Bar data={chartData} options={options} />};
//     </div>
//   );
// };

// export default BarChart;

import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Register the datalabels plugin
);

// Utility function for formatting numbers
const formatNumber = (num) => {
  if (num >= 1e6) return (num / 1e6).toFixed(2) + 'M';
  if (num >= 1e3) return (num / 1e3).toFixed(2) + 'k';
  return num.toString();
};

const BarChart = ({ chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'CPIC Data for 2024',
      },
      datalabels: {
        color: '#000', // Color for the data labels
        anchor: 'end',
        align: 'top',
        formatter: (value) => formatNumber(value), // Format data labels
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value) => formatNumber(value), // Format y-axis labels
        },
      },
    },
  };

  return chartData && <Bar data={chartData} options={options} />;
};

export default BarChart;
