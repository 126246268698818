import {
  Box,
  Button,
  Card,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import React from 'react';
import { Link } from 'react-router-dom';
import CircularILoader from '../../common/custom/circularLoader';
import CustomContainer from '../../common/custom/CustomContainer';
import { dashboardStyles } from './dasboard.styles';
import { useDashboard } from './useDashboard';

// Styled component to apply custom styles to the dropdown
const StyledMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: '300px',
    },
  },
};

const Dashboard = () => {
  const {
    data,
    overviewTab,
    overviewChartOptions,
    handleOverviewTab,
    selectedMonth,
    monthlyFilter,
    yearsFilterArray,
    selectedYear,
    handleYearChange,
    handleMonthChange,
    loading,
  } = useDashboard();
  const userDetails = localStorage.getItem('login-user');
  const parsedData = JSON.parse(userDetails);
  const getCurrentDate = () => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date().toLocaleDateString('en-US', options);
  };
  function formatNumber(value, isCurrency = false) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: isCurrency ? 'currency' : 'decimal',
      currency: 'USD',
      maximumFractionDigits: 2,
    });

    if (value >= 1_000_000_000) {
      return formatter.format(value / 1_000_000_000) + 'B';
    } else if (value >= 1_000_000) {
      return formatter.format(value / 1_000_000) + 'M';
    } else if (value >= 1_000) {
      return formatter.format(value / 1_000) + 'K';
    } else {
      return formatter.format(value);
    }
  }
  return (
    <>
      {loading ? (
        <div class='flex h-[126px] justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl'>
          <CircularILoader />
        </div>
      ) : (
        <CustomContainer>
          <Box sx={{ pt: { md: '40px', xs: '20px' } }}>
            <div className='p-6 space-y-6'>
              {/* Header */}
              <div className='flex justify-between items-center'>
                <div className='text-xl font-semibold'>
                  {`Welcome back, ${parsedData?.firstName} ${parsedData?.lastName}`}
                </div>
                <div className='text-gray-500'>{getCurrentDate()}</div>
              </div>

              {/* Filter Section */}
              <div className='bg-white p-4 rounded-lg shadow flex gap-8'>
                <div className='flex justify-between w-full max-w-[230px] items-center'>
                  <InputLabel id='year-select-label'>Select Year :</InputLabel>
                  <Select
                    labelId='year-select-label'
                    id='year-select'
                    value={selectedYear}
                    onChange={handleYearChange}
                    MenuProps={StyledMenuProps}
                    sx={{
                      borderRadius: '8px',
                      height: '40px',
                      width: '115px',
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D0D5DD',
                      },
                    }}
                  >
                    {yearsFilterArray.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className='flex justify-between w-full max-w-[230px] items-center'>
                  <InputLabel id='year-select-label'>Select Month :</InputLabel>
                  <Select
                    labelId='month-select-label'
                    id='month-select'
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    MenuProps={StyledMenuProps}
                    sx={{
                      borderRadius: '8px',
                      height: '40px',
                      width: '115px',
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D0D5DD',
                      },
                      '.MuiPaper-root': {
                        maxHeight: '300px !important',
                      },
                    }}
                  >
                    {monthlyFilter.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              {/* Metrics Summary Cards */}
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-6'>
                {[
                  {
                    title: 'Total Shipments',
                    value: data?.shipmentCount ?? 0,
                    change: '+12.4% ↑',
                    color: 'text-green-600',
                    path: '/shipments',
                  },
                  {
                    title: 'Total Shipment Value',
                    value: `${formatNumber(
                      data?.shipmentsTotalValue?.toFixed() ?? 0,
                      true
                    )}`,
                    change: '+18.7% ↑',
                    color: 'text-green-600',
                    path: '/shipments',
                  },
                  {
                    title: 'Total Premium',
                    value: `${formatNumber(
                      data?.totalRevenue?.toFixed() ?? 0,
                      true
                    )}`,
                    change: '+8.3% ↑',
                    color: 'text-green-600',
                    path: '/billing',
                  },
                  {
                    title: 'Total Entities',
                    value: `${data?.entitiesCount ?? 0}`,
                    change: '+5.4% ↑',
                    color: 'text-green-600',
                    path: '/entities',
                  },
                  {
                    title: 'Total Shipment Claims',
                    value: `${data?.shipmentClaimsCount?.toFixed() ?? 0}`,
                    change: '+2.8% ↑',
                    color: 'text-green-600',
                    path: '/claims',
                  },
                  {
                    title: 'Total Manual Claims',
                    value: `${data?.manualClaimsCount?.toFixed() ?? 0}`,
                    change: '-3.2% ↓',
                    color: 'text-red-600',
                    path: '/claims',
                  },
                ].map((metric) => (
                  <Link key={metric.title} to={metric.path} className='block'>
                    <div className='bg-white p-6 rounded-lg shadow relative overflow-hidden hover:shadow-lg transition transform hover:-translate-y-1'>
                      <div className='absolute left-0 top-0 h-full w-1 bg-[#77553D]'></div>
                      <div className='text-gray-500 text-sm mb-2'>
                        {metric.title}
                      </div>
                      <div className='text-2xl font-semibold'>
                        {metric.value}
                      </div>
                      {/* <div className={`text-sm mt-2 ${metric.color}`}>
                        {metric.change}
                      </div> */}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            {/* <div className='grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6'>
              {[
                {
                  title: 'Pending Shipment Claim',
                  value: data?.pendingShipmentClaimsCount?.toFixed() ?? 0,
                  bgColor: 'bg-[#77553D]',
                },
                {
                  title: 'Pending Manual Claim',
                  value: data?.pendingManualClaimsCount?.toFixed() ?? 0,
                  bgColor: 'bg-[#77553D]',
                },
              ].map((card, index) => (
                <div
                  key={index}
                  className={`p-6 rounded-xl ${card.bgColor} text-white shadow-lg flex flex-col justify-center items-center text-lg font-semibold hover:scale-105 transition-transform`}
                >
                  <div>{card.title}</div>
                  <div className='text-4xl font-bold'>{card.value}</div>
                </div>
              ))}
            </div> */}
            <Card sx={{ ...dashboardStyles.card, mt: 5 }}>
              <Card sx={dashboardStyles.innerCard}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '10px',
                  }}
                >
                  <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                    Overview
                  </Typography>
                  <Box
                    sx={{
                      // background: '#F9FAFB',
                      p: '4px',
                      borderRadius: '8px',
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    {[
                      { label: 'Shipments', value: 'shipment' },
                      { label: 'Billing', value: 'billing' },
                      { label: 'Entities', value: 'entities' },
                      { label: 'Shipment Claims', value: 'shipmentClaims' },
                      { label: 'Manual Claims', value: 'manualClaims' },
                      { label: 'Shipment Value', value: 'shipmentValue' },
                      // { label: 'Payment', value: 'payment' },
                    ].map((item, i) => (
                      <Button
                        key={i}
                        sx={{
                          background:
                            overviewTab === item.value
                              ? ' #77553D'
                              : 'transparent',
                          color:
                            overviewTab === item.value ? '#FFF' : '#667085',
                          fontWeight: 600,
                          fontSize: '14px',
                          border: '1px solid #D0D5DD',
                          '&:hover': {
                            background:
                              overviewTab === item.value
                                ? '#77553D'
                                : 'transparent',
                          },
                        }}
                        onClick={() => handleOverviewTab(item.value)}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </Box>
                </Box>
                <Box pt='20px'>
                  <Box>
                    <ReactECharts
                      option={overviewChartOptions}
                      style={{ width: '100%', height: '400px' }}
                    />
                  </Box>
                </Box>
              </Card>
            </Card>
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default Dashboard;
