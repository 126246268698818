import React, { useEffect, useState } from 'react';
import {
  CustomButton,
  CustomHeading,
  CustomModal,
} from '../../../common/custom';
import { CustomIcon } from '../../../common/customAssets';
import {
  changeShipmentsModalIcon,
  cross,
  switchOffIcon,
  switchOnIcon,
} from '../../../assets';
import CustomContainer from '../../../common/custom/CustomContainer';

const ChangeShipmentStatusModal = ({
  modalHeading,
  closeShipmentStatusModal,
  shipmentActionClickedData,
  approveShipmentHandler,
}) => {
  const [toggleDeliverySwitch, setDeliveryToggleDeliverySwitch] =
    useState(false);
  const [toggleApprovedSwitch, setApprovedToggleSwitch] = useState(false);
  const [toggleManuallyPaid, setToggleManuallyPaid] = useState(false);

  useEffect(() => {
    if (shipmentActionClickedData) {
      setDeliveryToggleDeliverySwitch(
        shipmentActionClickedData.deliveredStatus
      );
      setApprovedToggleSwitch(shipmentActionClickedData.approvedStatus);
      setToggleManuallyPaid(shipmentActionClickedData.isManuallyPaid);
    }
  }, [shipmentActionClickedData]);

  const updateHandler = async () => {
    //
    const { errors } = await approveShipmentHandler(
      parseInt(shipmentActionClickedData.id),
      {
        approvedStatus: toggleApprovedSwitch,
        deliveredStatus: toggleDeliverySwitch,
        isManuallyPaid: toggleManuallyPaid,
      }
    );
    if (!errors) {
      closeShipmentStatusModal();
    }
  };
  //

  return (
    <CustomModal>
      <div className='max-w-[496px] bg-pure-white w-full min-h-[272px]   rounded-[12px] flex flex-col items-center gap-1 justify-center    py-[24px] '>
        <div
          onClick={closeShipmentStatusModal}
          className=' cursor-pointer h-[24px] w-full flex justify-end max-w-[476px]'
        >
          <CustomIcon
            icon={cross}
            className=' h-[12px] w-[12px] flex justify-center items-center'
          />
        </div>
        {/* icon here */}
        <div
          className=' max-w-[476px]   w-full min-h-[72px] gap-[16px]
             flex '
        >
          <div className='bg-[#FEE4E2] border-[8px] border-[#FEF3F2] w-[48px] h-[48px] shrink-0 flex justify-center items-center rounded-full'>
            <CustomIcon icon={changeShipmentsModalIcon} />
          </div>
          <div className=' max-w-[400px] '>
            <CustomHeading className='text-[18px] font-inter leading-[28px] font-[600] mb-1'>
              {modalHeading}
            </CustomHeading>
            <CustomContainer className='mt-[36px]'>
              <div className='flex items-center mb-[14px] gap-[24px]'>
                <div className='text-[#475467] text-[14px] font-[500] font-inter'>
                  Delivered
                </div>
                <div
                  onClick={() =>
                    setDeliveryToggleDeliverySwitch((prevState) => !prevState)
                  }
                >
                  <CustomIcon
                    icon={toggleDeliverySwitch ? switchOnIcon : switchOffIcon}
                  />
                </div>
              </div>
              <div className='flex items-center mb-[14px] gap-[24px]'>
                <div className='text-[#475467] text-[14px] font-[500] font-inter'>
                  Approved
                </div>
                <div
                  onClick={() =>
                    setApprovedToggleSwitch((prevState) => !prevState)
                  }
                >
                  <CustomIcon
                    icon={toggleApprovedSwitch ? switchOnIcon : switchOffIcon}
                  />
                </div>
              </div>
              <div className='flex items-center mb-[14px] gap-[24px]'>
                <div className='text-[#475467] text-[14px] font-[500] font-inter'>
                  Manually Paid
                </div>
                <div
                  onClick={() =>
                    setToggleManuallyPaid((prevState) => !prevState)
                  }
                >
                  <CustomIcon
                    icon={toggleManuallyPaid ? switchOnIcon : switchOffIcon}
                  />
                </div>
              </div>
            </CustomContainer>
          </div>
        </div>
        <div className=' min-h-[44px] flex justify-end gap-[12px] w-full max-w-[476px] mt-[36px] '>
          <CustomButton
            onClick={closeShipmentStatusModal}
            type='button'
            text='Cancel'
            className='w-[87px] h-[44px] border-[1px] rounded-[8px]'
          />
          <CustomButton
            onClick={updateHandler}
            type='button'
            text='Update'
            className='w-[87px] h-[44px] bg-[#2EB200] rounded-[8px] text-pure-white'
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default ChangeShipmentStatusModal;
